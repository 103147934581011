import React from "react";
import styled from "styled-components";

export const CollapseButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  background: #1d1d1f;
  border-radius: 10px;
  z-index: 10;
  cursor: pointer;
  border: 2px solid #848484;
`;

export const CollapseButtonIcon = styled.div`
  aspect-ratio: 1/1;
  background: var(--noa-black);
  border-radius: 4px;
  z-index: 10;
  cursor: pointer;
  border: 2px solid white;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    background: white;
    width: 2px;
    left: 4px;
    top: 0;
    bottom: 0;
  }
`;

interface Props {
  onClick(): void;

  className?: string;
}

export function ToggleSidebarButton({ className, onClick }: Props) {
  return (
    <CollapseButton
      className={className}
      onClick={onClick}
      data-testid="sidebar-button"
    >
      <CollapseButtonIcon />
    </CollapseButton>
  );
}
