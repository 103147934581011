import React, { useMemo } from "react";
import { styled } from "styled-components";
import { NavLink } from "react-router-dom";
import { ChatBubble } from "iconoir-react";

import { Chat, Role } from "~/types/Chat";
import { useAnalytics } from "~/context/AnalyticsProvider";

const Container = styled(NavLink)`
  text-decoration: none;
  padding: 0.175rem 1rem;
  height: 3rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-gap: 1rem;
  align-items: center;
  border-radius: 10px;
  color: white;
  margin-bottom: 0.25rem;
  transition: background 0.2s ease-in-out;

  &.active {
    background: #434343;
  }
`;

const Title = styled.div`
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3rem;
  color: #adadad;
  font-weight: 400;
`;

const EmptyTitle = styled.span`
  font-style: italic;
  font-weight: 400;
`;

interface Props {
  chat: Chat;
}

export function ChatItem({ chat }: Props) {
  const title = useMemo(() => {
    const [message] = chat.messages.filter(
      (message) => message.role == Role.USER,
    );

    return message?.content;
  }, [chat.messages]);

  const { log } = useAnalytics();

  function onChatSelected() {
    log({
      type: "sidebar_chat_selected",
      payload: { chatId: chat.id, departmentId: chat.departmentId },
    });
  }

  return (
    <Container
      data-testid="chats-list-item"
      data-id={chat.id}
      to={`/chat/${chat.id}`}
      onClick={onChatSelected}
      className={({ isActive }) => (isActive ? "active" : "")}
    >
      <ChatBubble className="flip-horizontally" />
      <Title>{title || <EmptyTitle>No message content</EmptyTitle>}</Title>
    </Container>
  );
}
