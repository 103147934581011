import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { collection, CollectionReference } from "firebase/firestore";
import { db } from "~/integrations/firebase/firestore";
import { Department } from "~/types/Department";
import { useAuth } from "./AuthContext";

interface DepartmentContext {
  departments: Department[];
  loading: boolean;
  error?: Error;
}

export const DepartmentsContext = createContext<DepartmentContext>(
  undefined as never,
);

export const useDepartments = () => useContext(DepartmentsContext);

const reference = collection(
  db,
  "departments",
) as CollectionReference<Department>;

export const DepartmentsProvider = ({ children }: PropsWithChildren) => {
  const { authUser } = useAuth();
  const [departments = [], loading, error] =
    useCollectionData<Department>(reference);

  return (
    <DepartmentsContext.Provider value={{ departments, loading, error }}>
      {children}
    </DepartmentsContext.Provider>
  );
};
