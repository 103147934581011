import React from "react";
import styled, { keyframes } from "styled-components";
import { ArrowRotateRight, TickCircle } from "iconsax-react";

const Container = styled.div`
  display: flex;
  height: 3rem;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-bottom: 1rem;
`;

const PrimaryText = styled.span`
  font-weight: 500;
  font-size: 1.1rem;
  margin-right: 0.5rem;
`;

const SecondaryText = styled.span`
  color: var(--noa-purple-highlight);
  font-weight: 500;
  font-size: 1.1rem;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`;

const Text = styled.div``;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinningIcon = styled(ArrowRotateRight)`
  animation: ${spin} 2s linear infinite;
  transform-origin: 50% 56%;
  color: var(--noa-purple-highlight);
`;

const TickIcon = styled(TickCircle)`
  color: var(--noa-purple-highlight);
`;

type ChatItemInProgressProps = {
  action: string;
  actionInput: string;
  completed: boolean;
};

const ChatItemInProgress: React.FC<ChatItemInProgressProps> = ({
  action,
  actionInput,
  completed = false,
}) => {
  return (
    <Container>
      <IconContainer>
        {completed && <TickIcon size={20} />}
        {!completed && <SpinningIcon size="20" />}
      </IconContainer>
      <Text>
        <PrimaryText>{action}</PrimaryText>
        <SecondaryText>{actionInput}</SecondaryText>
      </Text>
    </Container>
  );
};

export default ChatItemInProgress;