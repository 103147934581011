import React, { useState } from "react";
import { styled } from "styled-components";
import { XIcon } from "./Icons/XIcon";
import { Text } from "~/components/Text";
import { createPortal } from "react-dom";
import { useAnalytics } from "~/context/AnalyticsProvider";

interface FeedbackPopupProps {
  initialFeedback?: string | null;
  onClose: () => void;
  onSubmit: (feedback: string) => void;
  container?: HTMLElement;
}

const PortalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #707070;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled.div`
  background: #292929;
  backgroundcolor: #292929;
  width: 65%;
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const BoldText = styled.p`
  font-weight: bold;
  font-size: 1.625rem;
  color: #ffffff;
  margin-left: 15px;
  margin-bottom: 10px;
  margin-top: 30px;
`;

const NormalText = styled.p`
  font-size: 1.125rem;
  color: #ffffff;
  margin-left: 15px;
`;

const FeedbackInput = styled.textarea`
  width: 90%;
  height: 200px;
  border-radius: 10px;
  border: none;
  padding: 10px;
  resize: none;
  background: #1c1c1c;
  color: #ffffff;
  fontcolor: #ffffff;
  font-size: 1.125rem;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 15px;

  &:focus {
    outline: none;
    border-color: inherit;
    box-shadow: none;
  }
`;

const SubmitButton = styled.button`
  display: block;
  width: 30%;
  padding: 10px;
  border: none;
  background: linear-gradient(90deg, #7255c5 0%, #6934ff 100%);
  font-size: 1.125rem;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  margin-top: 5px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const InputAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.span`
  position: absolute;
  right: 17px;
  top: 29px;
  width: 17px;
  border: 2px;
  height: 17px;
  cursor: pointer;
  font-size: 1.2rem;
`;

let modal = document.getElementById("modal");

if (!modal) {
  modal = document.createElement("div");
  modal.setAttribute("id", "modal");
  document.body.appendChild(modal);
}

export const FeedbackPopup: React.FC<FeedbackPopupProps> = ({
  initialFeedback = "",
  onClose,
  onSubmit,
}) => {
  const [inputValue, setInputValue] = useState(initialFeedback ?? "");
  const { log } = useAnalytics();

  const handleSubmit = () => {
    if (inputValue.trim() !== "") {
      onSubmit(inputValue);
    }
  };

  return createPortal(
    <PortalContainer>
      <PopupContainer data-testid="feedback-popup">
        <PopupContent>
          <CloseButton onClick={onClose} aria-label="Close">
            <XIcon />
          </CloseButton>
          <BoldText>
            <Text id="chats.chat.message.rating.modal.title" />
          </BoldText>
          <NormalText>
            <Text id="chats.chat.message.rating.modal.subtitle" />
          </NormalText>
          <InputAndButtonContainer>
            <FeedbackInput
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              aria-label="Feedback"
              data-testid="feedback-input"
            />
            <SubmitButton
              onClick={handleSubmit}
              disabled={inputValue.trim() === ""}
              aria-label="Submit"
              data-testid="feedback-submit-button"
            >
              <Text id="chats.chat.message.rating.modal.button.submit" />
            </SubmitButton>
          </InputAndButtonContainer>
        </PopupContent>
      </PopupContainer>
    </PortalContainer>,
    modal as HTMLElement,
  );
};
