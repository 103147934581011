// This file is generated by locala. Do not edit.
// Source: locala.yaml

export const strings = {
  "global.components.header.name": "Labs",
  "unsupported-screen.description": "We currently don't support small screens. Please use a larger device.",
  "maintenance.title": "Labs is sleeping",
  "maintenance.subtitle": "We are currently performing maintenance and will be back up shortly.",
  "login.intro.title": "Welcome to Labs",
  "login.button.login.with-google": "Continue with Google",
  "departments.title": "What would you like to try?",
  "departments.subtitle": "At Labs, we are currently running multiple experiments. Please select the experiment you want to participate in.",
  "chats.intro.title": "Hi, I'm Labs",
  "chats.intro.subtitle": "How can I help?",
  "chats.intro.disclaimer.title": "Welcome to Labs!",
  "chats.intro.disclaimer.content": "What would you like to do today?",
  "chats.sidebar.history.title": "Conversation history",
  "chats.sidebar.actions.new-chat": "New chat",
  "chats.chat.input.placeholder": "Ask me anything",
  "chats.chat.message.rating.modal.title": "We need your feedback on the response",
  "chats.chat.message.rating.modal.subtitle": "How could the response be improved? What did you miss?",
  "chats.chat.message.rating.modal.button.submit": "Submit",
  "files.upload.title": "Drag & drop your file here",
  "files.upload.button": "or upload",
  "files.upload.support-types.description": "Labs supports the following types",
  "files.upload.support-types.types": "PDF, DOCX, CSV, EPUB, TXT, XLSX, HTML",
  "files.upload.invalid-files.title": "Unsupported file format",
  "files.upload.invalid-files.suggestion": "Please try any of the following formats",
  "files.upload.invalid-files.try-again-button": "Try again",
  "files.upload.attached.note": "Please note:\n\nLarge files may take longer to upload",
  "files.upload.attached.upload-button": "Upload",
  "files.upload.failed.title": "File upload failed",
  "files.upload.failed.message": "Your file failed to upload.\n\n[Let us know](https://support.noa.chat) if the error persists.",
  "files.upload.finished.title": "Your file has been uploaded",
  "files.upload.finished.message": "And is ready to be used in the chat",
  "files.upload.finished.return-button": "Return to chat",
  "files.uploading.status.uploading": "Uploading your file",
  "files.uploading.status.processing": "Processing your file",
  "files.uploading.subtitle": "This may take a while,\n\nplease don’t close the window"
} as const;