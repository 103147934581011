import { styled } from "styled-components";
import logo from "../assets/images/logo.png";
import { useHeader } from "~/context/HeaderContext";
import { Transition } from "@headlessui/react";

const Container = styled(Transition)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 2.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  z-index: 10;

  &.hide {
    opacity: 0;
    transform: translateY(-50%);
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 130px;
  margin-top: 1rem;
`;

const Header = () => {
  const { visible } = useHeader();

  return (
    <Container
      show={visible}
      enterFrom="hide"
      enterTo="show"
      leaveFrom="show"
      leaveTo="hide"
    >
      <LogoWrapper>
        <Logo src={logo} alt="logo" />
      </LogoWrapper>
    </Container>
  );
};

export default Header;
