import React from "react";
import styled from "styled-components"; // Removed extra curly braces.
import { useNavigate } from "react-router-dom";
import { ChatAdd } from "iconoir-react";
import { Chat } from "~/types/Chat";
import { SidebarAction } from "~/components/Sidebar/SidebarAction";
import { Chats } from "~/components/Sidebar/Chats";
import { Text } from "~/components/Text";
import { ToggleSidebarButton } from "~/components/Sidebar/ToggleSidebarButton";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { useUser } from "~/context/UserContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 1.75rem 0;
  background-color: var(--secondary-bg-color);
  flex: 1;
  overflow: hidden;
  position: relative;
`;

const Actions = styled.div`
  padding: 0 1.75rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CollapseButton = styled(ToggleSidebarButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

interface Props {
  chats?: Chat[];

  toggleSidebar(): void;
}

export function Sidebar({ chats, toggleSidebar }: Props) {
  const navigate = useNavigate();
  const { user } = useUser();
  const { log } = useAnalytics();

  function onNewChat() {
    log({
      type: "sidebar_new_chat_selected",
      payload: {
        departmentId: user.departmentId!,
      },
    });

    navigate("/");
  }

  return (
    <>
      <Container>
        <CollapseButton onClick={toggleSidebar} />
        <Chats chats={chats} />
        <Actions>
          <SidebarAction
            onClick={onNewChat}
            icon={<ChatAdd className="flip-horizontally" />}
          >
            <Text id="chats.sidebar.actions.new-chat" />
          </SidebarAction>
        </Actions>
      </Container>
    </>
  );
}
