import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import LabsAvatar from "~/components/LabsAvatar";
import { throttle } from "lodash";

interface State {
  x: number;
  y: number;
  size: number;
  opacity: number;
}

type Update = {
  x?: "center" | number;
  y?: number;
  size?: number;
  opacity?: number;
};

interface AvatarAnimationContext {
  state: State;

  set(update: Update): void;
}

export const AvatarAnimationContext = createContext<AvatarAnimationContext>(
  undefined as never,
);

const INITIAL_AVATAR_SIZE = 500;

export const useAvatar = () => useContext(AvatarAnimationContext);

export const AvatarAnimationContextProvider = ({
  children,
}: PropsWithChildren) => {
  function set(current: State, update: Update) {
    const size = update.size ?? current.size;
    let x = update.x ?? current.x;

    if (x === "center") {
      x = window.innerWidth / 2 - size / 2;
    }

    return {
      ...current,
      x,
      size,
      y: update.y ?? current.y,
      opacity: update.opacity ?? current.opacity,
    };
  }

  const [state, setState] = useState<State>(() => {
    const initial: Update = {
      x: "center",
      y: window.innerHeight + INITIAL_AVATAR_SIZE * 2,
      size: INITIAL_AVATAR_SIZE,
      opacity: 0,
    };

    return set(
      {
        y: 0,
        size: 0,
        x: 0,
        opacity: 0,
      },
      initial,
    );
  });

  const setHandler = useCallback(
    throttle((update) => {
      setState((current) => set(current, update));
    }, 1000),
    [],
  );

  return (
    <AvatarAnimationContext.Provider
      value={{
        state,
        set: setHandler,
      }}
    >
      {children}
      <LabsAvatar />
    </AvatarAnimationContext.Provider>
  );
};
