import { Dislike, IconProps, Like1 as Like, Refresh } from "iconsax-react";
import { Rating, RatingType } from "~/types/Chat";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { styled } from "styled-components";
import { CopyIcon } from "~/components/Icons/CopyIcon";

const Container = styled.div`
  display: flex;
  height: 3rem;
  align-items: center;
`;

const Copied = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(10, 230, 106, 0.94);
  font-weight: 500;
  font-size: 0.9rem;
  margin: 0 0.25rem;
`;

interface Props {
  rating?: Rating;
  showRegenerate?: boolean;

  onLike(): void;

  onDislike(): void;

  onRegenerate(): void;

  onCopy(): Promise<boolean>;
}

export function ChatItemActions({
  onLike,
  onDislike,
  onRegenerate,
  onCopy,
  rating,
  showRegenerate,
}: Props) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) {
      return;
    }

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const hasFeedback = Boolean(rating?.feedbackText);

  return (
    <Container>
      <Action
        id="copy"
        icon={CopyIcon}
        cursor="pointer"
        onClick={async () => {
          if (await onCopy()) {
            setCopied(true);
          }
        }}
        variant="Outline"
        color={copied ? "rgba(10,230,106,0.94)" : undefined}
        title="Copy"
      />

      {copied && <Copied>Copied!</Copied>}

      <Action
        id="like"
        icon={Like}
        cursor={hasFeedback ? "default" : "pointer"}
        onClick={onLike}
        variant={rating?.value === RatingType.LIKE ? "Bold" : "Outline"}
        title="Like"
      />

      <Action
        id="dislike"
        icon={Dislike}
        cursor={hasFeedback ? "default" : "pointer"}
        onClick={onDislike}
        variant={rating?.value === RatingType.DISLIKE ? "Bold" : "Outline"}
        title="Dislike"
      />

      {showRegenerate && (
        <Action
          id="regenerate"
          icon={Refresh}
          cursor="pointer"
          onClick={onRegenerate}
          variant="Outline"
          title="Regenerate"
        />
      )}
    </Container>
  );
}

const ActionContainer = styled.button<{ $color?: string }>`
  background: transparent;
  border: none;
  height: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    color: ${({ $color = "#98F4CC" }) => $color};
    transition: all 0.2s ease-in-out;
  }

  &:hover .icon {
    color: ${({ $color = "#98F4CC" }) => $color};
  }
`;

interface ActionProps {
  id: string;
  icon: React.ComponentType<IconProps>;
  variant?: IconProps["variant"];
  cursor?: IconProps["cursor"];
  color?: IconProps["color"];
  title?: string;

  onClick(): void;
}

export function Action({
  id,
  onClick,
  icon: Icon,
  variant,
  cursor,
  color,
  title,
  children,
}: PropsWithChildren<ActionProps>) {
  return (
    <ActionContainer onClick={onClick} title={title} $color={color}>
      <Icon
        className="icon"
        color="currentColor"
        size="20"
        data-testid={`action-bar-button-${id}`}
        cursor={cursor}
        variant={variant}
      />
      {children}
    </ActionContainer>
  );
}
