import React, { PropsWithChildren } from "react";
import { styled } from "styled-components";

interface ContainerProps {
  $selected?: boolean;
  $block?: boolean;
}

const Container = styled.button<ContainerProps>`
  display: flex;
  padding: 16px;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  border-radius: 12px;
  font-size: 0.9rem;
  color: white;
  border: 1px solid #848484;
  background: ${({ $selected }) => ($selected ? "#98F4CC" : "#1d1d1f")};
  width: ${({ $block }) => ($block ? "100%" : "auto")};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #98F4CC;
    color: black;
  }
`;

const Icon = styled.div`
  margin-right: 0.75rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  onClick?(): void;

  href?: string;
  icon?: React.ReactElement;
  block?: boolean;
  selected?: boolean;
}

export function SidebarAction({
  onClick,
  block,
  selected,
  children,
  icon,
}: PropsWithChildren<Props>) {
  return (
    <Container onClick={onClick} $block={block} $selected={selected}>
      {icon && <Icon>{icon}</Icon>}
      {children}
    </Container>
  );
}
