import { useEffect, useState } from "react";

const SMALL_DEVICE_MAX_SIZE = 950;
const MIN_SUPPORT_SIZE = 840;

const getIsSmallDevice = () => window.innerWidth <= SMALL_DEVICE_MAX_SIZE;
const getIsSupportedDevice = () => window.innerWidth >= MIN_SUPPORT_SIZE;

const useIsSmallDevice = () => {
  const [isSmallDevice, setIsSmallDevice] = useState(getIsSmallDevice());
  const [isSupportedDevice, setIsSupportedDevice] = useState(
    getIsSupportedDevice(),
  );

  useEffect(() => {
    const onResize = () => {
      setIsSmallDevice(getIsSmallDevice());
      setIsSupportedDevice(getIsSupportedDevice());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return { isSmallDevice, isSupportedDevice };
};

export default useIsSmallDevice;
