import { createContext, PropsWithChildren, useContext } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { db } from "~/integrations/firebase/firestore";

interface ApplicationContext {
  maintenanceEnabled: boolean;
  features: {
    fileUploadEnabled?: boolean;
    messageRegenerationEnabled?: boolean;
    zendeskEnabled?: boolean;
    analyticsEnabled?: boolean;
    analyticsAuthEnabled?: boolean;
  };
  links?: {
    helpCentre?: string;
    feedback?: string;
  };
}

export const ApplicationContext = createContext<ApplicationContext>(
  undefined as never,
);

export const useApplication = () => useContext(ApplicationContext);

export const ApplicationProvider = ({ children }: PropsWithChildren) => {
  const reference = doc(db, "application", "configuration");

  const [data] = useDocumentData(reference);

  const application = {
    ...data,
    maintenanceEnabled:
      data?.application?.maintenanceEnabled ??
      import.meta.env.VITE_MAINTENANCE_ENABLED === "true",
    features: data?.features ?? {},
  };

  return (
    <ApplicationContext.Provider value={application}>
      {children}
    </ApplicationContext.Provider>
  );
};
