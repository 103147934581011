import React from "react";
import { FileRejection } from "react-dropzone";
import { Text } from "~/components/Text";
import {
  Button,
  Content,
  Details,
  DocumentErrorIcon,
  Drop,
  DropMessage,
  FileTypes,
  OrUploadButton,
  Support,
  Title,
  UploadIcon,
} from "./styled";

interface UploadScreenProps {
  invalidFiles: FileRejection[];
  isActive: boolean;
  inputProps: any;
  rootProps: any;

  onClear(): void;
}

export function UploadScreen({
  isActive,
  inputProps,
  rootProps,
  invalidFiles,
  onClear,
}: UploadScreenProps) {
  if (invalidFiles.length) {
    return (
      <Content>
        <Details>
          <DocumentErrorIcon />

          <Title>
            <Text id="files.upload.invalid-files.title" />
          </Title>

          <Support>
            <Text id="files.upload.invalid-files.suggestion" />
            <FileTypes>
              <Text id="files.upload.support-types.types" />
            </FileTypes>
          </Support>
        </Details>

        <Button onClick={onClear}>
          <Text id="files.upload.invalid-files.try-again-button" />
        </Button>
      </Content>
    );
  }

  return (
    <Drop {...rootProps} $isActive={isActive}>
      <input {...inputProps} />

      <DropMessage>
        <UploadIcon />

        <Title>
          <Text id="files.upload.title" />
        </Title>
        <OrUploadButton>
          <Text id="files.upload.button" />
        </OrUploadButton>
      </DropMessage>

      <Support>
        <Text id="files.upload.support-types.description" />

        <FileTypes>
          <Text id="files.upload.support-types.types" />
        </FileTypes>
      </Support>
    </Drop>
  );
}
