import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  User,
  UserCredential,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "~/integrations/firebase/auth";
import { styled } from "styled-components";
import { useAnalytics } from "~/context/AnalyticsProvider";

interface AuthContext {
  authUser?: User | null;
  loading: boolean;
  isLoggedIn: boolean;

  signInWithOauth(type: OAuthProviderType): Promise<UserCredential>;

  signOut(): Promise<void>;
}

export const AuthContext = createContext<AuthContext>(undefined as never);

export const useAuth = () => useContext(AuthContext);

const OAUTH_PROVIDERS = {
  google: new GoogleAuthProvider(),
};

type OAuthProviderType = keyof typeof OAUTH_PROVIDERS;

/**
 * This component is responsible for reporting on the authentication state of the app.
 */
const LoggedInState = styled.div`
  display: none;
`;

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [authUser, loading] = useAuthState(auth);
  const { setUser } = useAnalytics();

  async function signInWithOauth(type: OAuthProviderType) {
    const provider = OAUTH_PROVIDERS[type];

    if (!provider) {
      throw new Error(`No provider found for type ${type}`);
    }

    return signInWithPopup(auth, provider);
  }

  async function signOut() {
    return auth.signOut();
  }

  useEffect(() => {
    setUser(authUser?.uid);
  }, [authUser?.uid]);

  const isLoggedIn = !!authUser;

  return (
    <AuthContext.Provider
      value={{ authUser, loading, isLoggedIn, signInWithOauth, signOut }}
    >
      {children}
      {isLoggedIn && <LoggedInState data-testid="user-logged-in" />}
    </AuthContext.Provider>
  );
};
