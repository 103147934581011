import { useEffect, useRef, useState } from "react";

export const useTypingAnimation = (
  response: string,
  isLoading: boolean = true,
  MIN_TIMEOUT: number,
  MAX_TIMEOUT: number,
) => {
  const [displayedText, setDisplayedText] = useState(response);
  const isTypingRef = useRef(false);

  useEffect(() => {
    if (isTypingRef.current) {
      return;
    }

    if (response.length > displayedText.length && isLoading) {
      isTypingRef.current = true;

      const timeout =
        (MAX_TIMEOUT - MIN_TIMEOUT) /
          (response.length - displayedText.length || 1) +
        MIN_TIMEOUT;

      setTimeout(() => {
        setDisplayedText(response.slice(0, displayedText.length + 1));
        isTypingRef.current = false;
      }, timeout);
    }
  }, [displayedText, response, isLoading, MIN_TIMEOUT, MAX_TIMEOUT]);

  return displayedText;
};
