import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "styled-components";
import { useAuth } from "~/context/AuthContext";
import Button from "~/components/Button";
import { useHeader } from "~/context/HeaderContext";
import { FadeIn } from "~/components/FadeIn";
import { Text } from "~/components/Text";
import { LabsContainer } from "~/components/LabsAvatar";
import { useAnalytics } from "~/context/AnalyticsProvider";

const Title = styled(FadeIn)`
  color: #fff;
  font-size: 37.799px;
  font-weight: 700;
`;

const LoginContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30.239px;
  justify-content: center;
  padding-bottom: 200px;
  flex: 1;
  z-index: 1;
`;

const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;

const Actions = styled(FadeIn)``;

const LabsAvatar = styled(LabsContainer)`
  position: absolute;
  bottom: -250px;
  width: 100%;
  height: 500px;
`;

const Login: React.FC = () => {
  const navigate = useNavigate();
  const header = useHeader();
  const { signInWithOauth, isLoggedIn } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { log, setUser } = useAnalytics();

  async function signInWithGoogle() {
    try {
      const credentials = await signInWithOauth("google");

      if (credentials) {
        setUser(credentials.user.uid);

        log({ type: "login_success" });

        navigate("/");
      }
    } catch (error) {
      log({
        type: "login_failed",
        payload: {
          errorCode: error.code,
          errorMessage: error.message,
        },
      });

      console.error("Failed to login with google", error.code, error.message);

      setErrorMessage(error.message);
    }
  }

  useEffect(() => {
    header.setVisible(true);
  }, []);

  return (
    <LoginContainer>
      <LoginContainerContent>
        <Title data-testid="login-introduction">
          <Text id="login.intro.title" />
        </Title>
        <Actions delay={100}>
          <Button onClick={signInWithGoogle} data-testid="login-button">
            <Text id="login.button.login.with-google" />
          </Button>
        </Actions>
      </LoginContainerContent>

      <LabsAvatar />
    </LoginContainer>
  );
};

export default Login;
