interface Props {
  className?: string;

  onClick?(): void;
}

export function XIcon({ className, onClick }: Props) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2929 0.292894C20.6834 -0.0976306 21.3166 -0.0976306 21.7071 0.292894C22.0976 0.683418 22.0976 1.31658 21.7071 1.70711L12.4142 11L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L11 12.4142L1.70712 21.7071C1.3166 22.0976 0.683432 22.0976 0.292908 21.7071C-0.0976162 21.3166 -0.0976162 20.6834 0.292908 20.2929L9.5858 11L0.292908 1.70711C-0.0976158 1.31658 -0.0976158 0.683418 0.292908 0.292894C0.683433 -0.0976306 1.3166 -0.0976306 1.70712 0.292894L11 9.58579L20.2929 0.292894Z"
        fill="#CFCFCF"
      />
    </svg>
  );
}
