import { Timestamp } from "firebase/firestore";

export enum FileStatus {
  REQUESTED = "REQUESTED",
  UPLOAD_URL_GENERATED = "UPLOAD_URL_GENERATED",
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  ERROR = "ERROR",
  PROCESSED = "PROCESSED",
}

export interface FileError {
  message: string;
  updatedAt: Timestamp;
}

export interface File {
  id: string;
  userId: string;
  departmentId: string;
  organisationId: string | null;
  status: FileStatus;
  error: FileError | null;
  fileName: string;
  fileType: string;
  updatedAt: number;
  uploadUrl: string | null;
  gcsPath: string | null;
  idempotencyKey: string | null;
}
