import React from "react";
import styled from "styled-components";
import { Link, LinkProps } from "react-router-dom";

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const Container = styled(Link)`
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 55px;
  cursor: pointer;
  color: #adadad;
  text-decoration: none;
  font-weight: normal;

  &:visited,
  &:hover {
    color: #adadad;
  }
`;

interface Props extends LinkProps {
  icon: string;
  to: string;
}

export function MenuItem({ icon, to, children, ...props }: Props) {
  return (
    <Container to={to} {...props}>
      <Icon src={icon} alt="icon" />
      {children}
    </Container>
  );
}
