import React from "react";

interface Props {
  color?: string;
  size?: number | string;
  className?: string;
}

export function CopyIcon({
  color = "#98F4CC",
  className = "",
  size = 20,
}: Props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={size}
    >
      <mask
        id="mask0_1510_31626"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <path d="M0 0H26V26H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1510_31626)">
        <path
          d="M6.33432 3H4.94558C3.8728 3 3 3.8723 3 4.94452V6.33254C3 6.79321 3.37361 7.16662 3.83457 7.16662C4.29552 7.16662 4.66919 6.79321 4.66919 6.33254V4.94452C4.66919 4.79475 4.79573 4.66822 4.94558 4.66822H6.33432C6.79528 4.66822 7.16889 4.29475 7.16889 3.83408C7.16889 3.37342 6.79528 3 6.33432 3ZM13.0002 4.66822H14.3889C14.5388 4.66822 14.6653 4.79475 14.6653 4.94452V6.33254C14.6653 6.79321 15.039 7.16662 15.4999 7.16662C15.9609 7.16662 16.3345 6.79321 16.3345 6.33254V4.94452C16.3345 3.8723 15.4617 3 14.3889 3H13.0002C12.5392 3 12.1656 3.37342 12.1656 3.83408C12.1656 4.29475 12.5392 4.66822 13.0002 4.66822ZM21.0545 8.56205H11.6108C10.538 8.56205 9.66528 9.43441 9.66528 10.5066V21.0554C9.66528 22.1276 10.538 23 11.6108 23H21.0545C22.1273 23 23 22.1276 23 21.0554V10.5066C23 9.43441 22.1273 8.56205 21.0545 8.56205ZM21.3309 21.0554C21.3308 21.1287 21.3016 21.1989 21.2498 21.2507C21.198 21.3025 21.1278 21.3316 21.0545 21.3317H11.6108C11.5375 21.3316 11.4673 21.3025 11.4155 21.2507C11.3636 21.1989 11.3345 21.1287 11.3344 21.0554V10.5066C11.3345 10.4334 11.3636 10.3632 11.4155 10.3114C11.4673 10.2596 11.5375 10.2304 11.6108 10.2303H21.0545C21.1278 10.2304 21.198 10.2596 21.2498 10.3114C21.3016 10.3632 21.3308 10.4334 21.3309 10.5066V21.0554ZM6.33432 15.7697H4.94558C4.79573 15.7697 4.66919 15.6431 4.66919 15.4934V14.1053C4.66919 13.6447 4.29552 13.2713 3.83457 13.2713C3.37361 13.2713 3 13.6447 3 14.1053V15.4934C3 16.5656 3.8728 17.4379 4.94558 17.4379H6.33432C6.79528 17.4379 7.16889 17.0645 7.16889 16.6038C7.16889 16.1431 6.79528 15.7697 6.33432 15.7697Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
