import { styled } from "styled-components";
import { Chat } from "~/types/Chat";
import { ChatItem } from "~/components/Sidebar/ChatItem";
import { Text } from "~/components/Text";

const Container = styled.div`
  margin: 0 0 1.75rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  & {
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-moz-scrollbar {
      width: 0.5rem;
    }
  }
`;

const Title = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.95rem;
  text-align: left;
  font-weight: 500;
  padding: 0 1.75rem 1rem;
  margin: 0 0.5rem;
`;

const Items = styled.div`
  overflow-y: auto;
  flex: 1;
  padding: 0 0.75rem;
  margin: 0 0.5rem;
`;

interface Props {
  chats?: Chat[];
}

export function Chats({ chats = [] }: Props) {
  return (
    <Container>
      <Title>
        <Text id="chats.sidebar.history.title" />
      </Title>

      <Items data-testid="chats-list">
        {chats.map((chat) => (
          <ChatItem key={chat.id} chat={chat} />
        ))}
      </Items>
    </Container>
  );
}
