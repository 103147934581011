import { createContext, PropsWithChildren, useContext } from "react";
import { useParams } from "react-router-dom";
import { useChats } from "~/context/ChatsContext";
import {
  arrayUnion,
  doc,
  DocumentReference,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

import { Chat, ChatStatus, RatingType, Role } from "~/types/Chat";
import { FileStatus } from "~/types/File";
import { v4 as uuidv4 } from "uuid";
import { db } from "~/integrations/firebase/firestore";
import { useFiles } from "~/context/FilesContext";
import { useAuth } from "~/context/AuthContext";
import { useAnalytics } from "~/context/AnalyticsProvider";

export enum Rating {
  LIKE = 1,
  DISLIKE = -1,
}

interface ChatContext {
  chat: Chat;

  addMessage(
    message: string,
    previousMessageId: string | undefined,
  ): Promise<void>;

  rateMessage(
    messageId: string,
    rating: RatingType,
    feedbackText: string | null,
  ): Promise<void>;

  createFileUploadRequest(
    fileName: string,
    fileType: string,
  ): Promise<DocumentReference>;

  regenerateMessage(): Promise<void>;

  addVoiceMessage(
    message: string,
    role: Role,
    previousMessageId?: string,
  ): Promise<void>;
}

export const ChatContext = createContext<ChatContext>(undefined as never);

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams<{ id: string }>();
  const { authUser } = useAuth();

  const { chats, reference } = useChats();
  const { reference: filesReference } = useFiles();

  const chat = chats.find((chat: any) => chat.id === id)!;

  async function addMessage(message: string, previousMessageId?: string) {
    const messageId = uuidv4();

    const ref = doc(reference, id);

    await updateDoc(ref, {
      updatedAt: serverTimestamp(),
      status: ChatStatus.REQUESTED,
      messages: arrayUnion({
        id: messageId,
        content: message,
        role: Role.USER,
        updatedAt: new Date(),
        previousMessageId: previousMessageId ?? null,
        model: null,
        platform: null,
        errorMessage: null,
        idempotencyKey: null,
      }),
    });
  }

  async function addVoiceMessage(
    message: string,
    role: Role,
    previousMessageId?: string,
  ) {
    const messageId = uuidv4();

    const ref = doc(reference, id);

    let lastMessageId: string | null = null;
    if (chat.messages.length > 0) {
      lastMessageId = chat.messages[chat.messages.length - 1].id;
    }

    await updateDoc(ref, {
      updatedAt: serverTimestamp(),
      status: ChatStatus.READY,
      messages: arrayUnion({
        id: messageId,
        content: message,
        role: role,
        updatedAt: new Date(),
        previousMessageId: previousMessageId ?? lastMessageId,
        model: {
          modelName: "gpt-4o-realtime-preview",
          platform: "ChatOpenAI",
          params: {
            temperature: 0.8,
            maxTokens: 4096,
            n: 1,
            topP: 1,
            presencePenalty: 0,
            frequencyPenalty: 0,
          },
        },
        errorMessage: null,
        idempotencyKey: null,
      }),
    });
  }

  async function rateMessage(
    messageId: string,
    rating: RatingType,
    feedbackText: string | null,
  ) {
    const ref = doc(reference, id);
    const messageIndex = chat.messages.findIndex(
      (message) => message.id === messageId,
    );

    await runTransaction(db, async (transaction) => {
      const messages = (await transaction.get(ref)).get("messages");

      messages[messageIndex] = {
        ...messages[messageIndex],
        rating: {
          value: rating,
          updatedAt: new Date(),
          feedbackText,
        },
      };

      await transaction.update(ref, { messages: messages });
    });
  }

  async function createFileUploadRequest(fileName: string, fileType: string) {
    const ref = doc(reference, id);

    return runTransaction(db, async (transaction) => {
      const fileId = uuidv4();

      const chat = await transaction.get(ref);
      const departmentId = chat.get("departmentId");
      const files = chat.get("files") ?? {};

      const fileReference = doc(filesReference, fileId);

      // Create file document
      transaction.set(fileReference, {
        fileName,
        fileType,
        id: fileId,
        departmentId,
        status: FileStatus.REQUESTED,
        userId: authUser!.uid,
        updatedAt: serverTimestamp(),
        error: null,
        gcsPath: null,
        uploadUrl: null,
        organisationId: null,
        idempotencyKey: null,
      });

      // Attach file to chat
      await transaction.update(ref, {
        updatedAt: serverTimestamp(),
        files: {
          ...files,
          [fileId]: {
            updatedAt: serverTimestamp(),
          },
        },
      });

      return fileReference;
    });
  }

  async function updateFeedback(messageId: string, feedbackText: string) {
    const ref = doc(reference, id);
    const messageIndex = chat.messages.findIndex(
      (message) => message.id === messageId,
    );

    await runTransaction(db, async (transaction) => {
      const messages = (await transaction.get(ref)).get("messages");
      messages[messageIndex] = {
        ...messages[messageIndex],
        rating: {
          ...messages[messageIndex].rating,
          updatedAt: new Date(),
          feedbackText: feedbackText,
        },
      };
      await transaction.update(ref, { messages: messages });
    });
  }

  async function regenerateMessage() {
    const ref = doc(reference, id);

    await updateDoc(ref, {
      status: ChatStatus.REGENERATE,
      updatedAt: serverTimestamp(),
    });
  }

  if (!chat) {
    return null;
  }

  return (
    <ChatContext.Provider
      value={{
        chat,
        addMessage,
        rateMessage,
        createFileUploadRequest,
        regenerateMessage,
        addVoiceMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
