import React, { useEffect } from "react";
import { useAuth } from "~/context/AuthContext";
import { useAnalytics } from "~/context/AnalyticsProvider";

function LogoutPage() {
  const { signOut } = useAuth();
  const { log } = useAnalytics();

  useEffect(() => {
    console.log("Logging out");
    signOut().then();

    log({ type: "logout_success" });
  }, []);

  return null;
}

export default LogoutPage;
