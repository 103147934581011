import React, { useState } from "react";
import { Send } from "iconoir-react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { styled } from "styled-components";
import Textarea from "react-textarea-autosize";

import Dropzone from "./Dropzone";
import { text } from "~/components/Text";

const Container = styled(Form)`
  display: flex;
  border: 2px solid #9d9d9d;
  border-radius: 15px;
  background-color: var(--secondary-bg-color);
  overflow: hidden;
  @media (max-width: 600px) {
    margin: 3px;
  }
`;

const Input = styled<any>(Textarea)`
  padding: 1.125rem 1.5rem;
  font-family: inherit;
  flex: 1;
  line-height: 1.5rem;
  outline: none;
  resize: none;
  border: 0;
  background: transparent;
  color: white;
  font-size: inherit;
`;

const SubmitButton = styled.button`
  background: transparent;
  border: 0;
  color: white;
  width: 3.5rem;
  opacity: 0.4;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s linear;

  &:hover {
    background: #252525;
  }
`;

interface FormValues {
  message: string;
}

const schema = yup.object({
  message: yup.string().trim().required("Message is required"),
});

interface Props {
  disabled?: boolean;

  submitMessage(message: string): Promise<void>;
}

export function InputPrompt({ disabled, submitMessage }: Props) {
  const [showDropzone] = useState(false);

  // Function to manage selected files in Dropzone
  const handleDropzoneCallback = (acceptedFiles: File[]) => {
    console.log("Selected files:", acceptedFiles);
  };

  async function onSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    if (disabled) {
      return;
    }

    await submitMessage(values.message);
    helpers.resetForm();
  }

  return (
    <Formik
      initialValues={{ message: "" }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ values, isValid, submitForm }) => (
        <Container>
          {showDropzone && <Dropzone callback={handleDropzoneCallback} />}

          <Field name="message">
            {({ field }: FieldProps) => (
              <Input
                autoFocus
                data-testid="chat-input"
                type="text"
                maxRows={5}
                placeholder={text("chats.chat.input.placeholder")}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    submitForm();
                  }
                }}
                {...field}
              />
            )}
          </Field>

          <SubmitButton
            disabled={!isValid || disabled}
            data-testid="chat-submit-button"
            type="submit"
          >
            <Send />
          </SubmitButton>
        </Container>
      )}
    </Formik>
  );
}

export default InputPrompt;
