import React, { useEffect } from "react";
import { styled } from "styled-components";
import { Outlet } from "react-router-dom";
import { Transition } from "@headlessui/react";

import { Sidebar } from "~/components/Sidebar/Sidebar";
import { useChats } from "~/context/ChatsContext";
import useIsSmallDevice from "~/hooks/useIsSmallDevice";
import { useLayout } from "~/context/LayoutContext";

import { BurgerMenu } from "~/components/BurgerMenu/BurgerMenu";
import { ChatHeader } from "~/routes/Chat/ChatHeader";
import { useAnalytics } from "~/context/AnalyticsProvider";

interface ContainerProps {
  $menuOpen: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  margin-left: ${({ $menuOpen }) => ($menuOpen ? "var(--sidebar-width)" : 0)};
  position: relative;
  transition: margin-left 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  @media (max-width:600px) { 
    margin-left: 0;
  }
`;

const LeftMenu = styled(Transition)`
  width: var(--sidebar-width);
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);

  &.hide {
    transform: translateX(-100%);
  }

  &.show {
    transform: translateX(0);
  }
  @media (max-width:600px) { 
    width: 100%;
  }
`;

const RightMenu = styled(Transition)`
  width: var(--sidebar-width);
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);

  &.hide {
    transform: translateX(100%);
  }

  &.show {
    transform: translateX(0);
  }

  @media (max-width:600px) { 
    width: 100%;
  }
`;

export default function ChatLayout() {
  const { chats } = useChats();
  const { showLeftDrawer, showRightDrawer, setShowLeftDrawer } = useLayout();
  const { isSmallDevice } = useIsSmallDevice();
  const { log } = useAnalytics();

  useEffect(() => {
    setShowLeftDrawer(!isSmallDevice);
  }, [isSmallDevice]);

  function onToggleSidebar(showLeftDrawer: boolean) {
    log({
      type: "sidebar_toggle_clicked",
      payload: {
        closed: !showLeftDrawer,
      },
    });

    setShowLeftDrawer(showLeftDrawer);
  }

  return (
    <Container $menuOpen={showLeftDrawer}>
      <ChatHeader />

      <LeftMenu
        show={showLeftDrawer}
        enterFrom="hide"
        enterTo="show"
        leaveFrom="show"
        leaveTo="hide"
      >
        <Sidebar
          chats={chats}
          toggleSidebar={() => onToggleSidebar(!showLeftDrawer)}
        />
      </LeftMenu>

      <RightMenu
        show={showRightDrawer}
        enterFrom="hide"
        enterTo="show"
        leaveFrom="show"
        leaveTo="hide"
      >
        <BurgerMenu />
      </RightMenu>

      <Outlet />
    </Container>
  );
}
