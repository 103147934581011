import { auth } from "../../integrations/firebase/auth";
import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button"; // Import the Button component
import { RealtimeClient } from "@openai/realtime-api-beta";
import { WavStreamPlayer, WavRecorder } from "../../wavtools";
import { Chat, Role } from "~/types/Chat";

interface Props {
  startChat?: (() => Promise<void>) | undefined;
  addVoiceMessage?:
    | ((
        message: string,
        role: Role,
        previousMessageId?: string,
      ) => Promise<void>)
    | undefined;
  chat?: Chat;
}

export default function ProblemVoiceButton({
  startChat,
  addVoiceMessage,
  chat,
}: Props) {
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [audioPlayer, setAudioPlayer] = useState<WavStreamPlayer | undefined>();
  const [client, setClient] = useState<RealtimeClient | undefined>();
  const [audioRecorder, setAudioRecorder] = useState<WavRecorder | undefined>();
  const hasStartedVoice = useRef(false);

  useEffect(() => {
    const isVoice =
      new URLSearchParams(window.location.search).get("voice") === "true";

    if (
      isVoice &&
      !hasStartedVoice.current &&
      (!chat || chat?.messages.length === 0)
    ) {
      hasStartedVoice.current = true;
      startSession();
    }
  }, []);

  useEffect(() => {
    if (isSessionActive) {
      stopSession();
    }
  }, []);

  useEffect(() => {
    if (client && audioPlayer) {
      client.on("conversation.updated", (event: any) => {
        const { item, delta } = event;
        queueAudio(item, delta);
      });

      client.on("conversation.interrupted", () => {
        audioPlayer.interrupt();
      });

      // Setup for application control flow
      const handler = (event: any, ...args: any[]) => {
        const { item, delta } = client.conversation.processEvent(
          event,
          ...args,
        );
        return { item, delta };
      };
      const handlerWithDispatch = (event: any, ...args: any[]) => {
        const { item, delta } = handler(event, ...args);
        if (item) {
          // FIXME: If statement is only here because item.input_audio_transcription.completed
          //        can fire before `item.created`, resulting in empty item.
          //        This happens in VAD mode with empty audio
          client.dispatch("conversation.updated", { item, delta });
        }
        return { item, delta };
      };

      client.realtime.off("server.response.output_item.done");

      client.realtime.on("server.session.updated", async (event: any) => {
        let buffer = "Transcript of the conversation so far:\n";
        if (
          chat?.messages.length &&
          chat.messages.length > 0 &&
          client.conversation.items.length === 0
        ) {
          for (const message of chat.messages) {
            if (message.role === Role.USER) {
              buffer += `User: ${message.content}\n`;
            } else if (message.role === Role.AI) {
              buffer += `Assistant: ${message.content}\n`;
            }
          }
          if (buffer.length > 0) {
            await client.realtime.send("conversation.item.create", {
              type: "conversation.item.create",
              item: {
                type: "message",
                role: "user",
                content: [{ type: "input_text", text: buffer }],
              },
            });
          }
        }
      });

      client.realtime.on(
        "server.response.output_item.done",
        async (event: any) => {
          const { item } = handlerWithDispatch(event);
          if (item.status === "completed") {
            client.dispatch("conversation.item.completed", { item });
            if (addVoiceMessage) {
              addVoiceMessage(
                item.formatted.transcript.length > 0
                  ? item.formatted.transcript
                  : item.formatted.text,
                Role.AI,
              );
            }
          }
        },
      );

      client.realtime.on(
        "server.conversation.item.input_audio_transcription.completed",
        async (event: any) => {
          if (addVoiceMessage && event.transcript) {
            addVoiceMessage(event.transcript, Role.USER);
          }
        },
      );

      client.connect();
    }
  }, [client, audioPlayer]);

  const initAudioPlayer = async () => {
    const player = new WavStreamPlayer({ sampleRate: 24000 });
    setAudioPlayer(player);
    await player.connect();
  };

  const initClient = async () => {
    const idToken = await auth.currentUser?.getIdToken(true);
    const client = new RealtimeClient({
      url: "wss://realtime-api-relay-434505839953.europe-west4.run.app",
      apiKey: idToken,
      dangerouslyAllowAPIKeyInBrowser: true,
    });
    setClient(client);
  };

  const initAudioRecorder = async () => {
      const recorder = new WavRecorder({ sampleRate: 24000 });
      setAudioRecorder(recorder);

      recorder.begin().then(() => {
        recorder.record((data) => {
          try {
            const { mono, raw } = data;
            if (client) {
              client.appendInputAudio(mono);
            }
          } catch (e) {
            if (isSessionActive) {
              stopSession();
            }
            // TODO: add toast, error & please restart session
            console.error(
                "An error occurred, while trying to send audio to Realtime API.",
                e,
            );
          }
        });
      });
  };

  const queueAudio = (event: any, delta: any) => {
    if (audioPlayer && delta?.audio) {
      audioPlayer.add16BitPCM(delta.audio, event.id);
    }
  };

  const startSession = async () => {
    if (startChat) {
      await startChat();
    } else {
      if (!isSessionActive) {
        await initClient();
        await initAudioPlayer();
        await initAudioRecorder();
        setIsSessionActive(true);
      }
    }
  };

  const stopSession = async () => {
    setIsSessionActive(false);
    if (audioPlayer) {
      await audioPlayer.interrupt();
      setAudioPlayer(undefined);
    }
    if (audioRecorder) {
      await audioRecorder.pause();
      await audioRecorder.quit();
      audioRecorder.listenForDeviceChange(null);
      setAudioRecorder(undefined);
    }
    if (client) {
      client.disconnect();
      setClient(undefined);
    }
  };

  return (
    <Button onClick={isSessionActive ? stopSession : startSession}>
      {isSessionActive ? "End Voice Chat" : "Start Voice Chat"}
    </Button>
  );
}
