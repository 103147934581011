import {
  Firestore,
  getFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { app } from "./app";

export const db: Firestore = getFirestore(app);

if (import.meta.env.VITE_FIREBASE_EMULATORS_ENABLED) {
  connectFirestoreEmulator(db, "127.0.0.1", 8081);
}
