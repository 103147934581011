import { createContext, PropsWithChildren, useContext, useState } from "react";

interface HeaderContext {
  visible: boolean;

  setVisible(visible: boolean): void;
}

export const HeaderContext = createContext<HeaderContext>(undefined as never);

export const useHeader = () => useContext(HeaderContext);

export const HeaderProvider = ({ children }: PropsWithChildren) => {
  const [visible, setVisible] = useState(false);

  return (
    <HeaderContext.Provider value={{ visible, setVisible }}>
      {children}
    </HeaderContext.Provider>
  );
};
