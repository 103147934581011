import { useEffect } from "react";
import { useApplication } from "~/context/ApplicationContext";

interface Props {
  apiKey?: string;
}

export function Zendesk({ apiKey }: Props) {
  const { features } = useApplication();

  const enabled = Boolean(features.zendeskEnabled);

  useEffect(() => {
    if (!apiKey || !enabled) {
      return;
    }

    let script = document.getElementById("ze-snippet") as HTMLScriptElement;

    if (!script) {
      script = document.createElement("script");
      script.id = "ze-snippet";
      script.src = "https://static.zdassets.com/ekr/snippet.js?key=" + apiKey;
      script.async = true;

      document.body.appendChild(script);
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [apiKey, enabled]);

  return null;
}
