import "~/integrations/firebase/analytics";
import "./integrations/sentry";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./context/AuthContext";
import { HeaderProvider } from "~/context/HeaderContext";
import { AvatarAnimationContextProvider } from "./context/AvatarAnimationContext";
import { ApplicationProvider } from "~/context/ApplicationContext";
import { LayoutContextProvider } from "./context/LayoutContext";
import { AnalyticsProvider } from "~/context/AnalyticsProvider";
import { Zendesk } from "~/components/Zendesk";

import "./styles/variables.css";
import "./styles/utilities.css";
import "./styles/global.css";

console.log("Running in", import.meta.env.MODE, "mode!");

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ApplicationProvider>
      <AnalyticsProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <LayoutContextProvider>
              <AvatarAnimationContextProvider>
                <HeaderProvider>
                  <App />
                  <Zendesk apiKey={import.meta.env.VITE_ZENDESK_KEY} />
                </HeaderProvider>
              </AvatarAnimationContextProvider>
            </LayoutContextProvider>
          </BrowserRouter>
        </AuthContextProvider>
      </AnalyticsProvider>
    </ApplicationProvider>
  </StrictMode>,
);

reportWebVitals();
