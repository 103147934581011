import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import IntroSection from "~/components/IntroSection";
import { InputPrompt } from "~/components/InputPrompt";
import { ChatStatus, Role } from "~/types/Chat";
import { useChats } from "~/context/ChatsContext";

import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useAuth } from "~/context/AuthContext";
import { useUser } from "~/context/UserContext";
import { FadeIn } from "~/components/FadeIn";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { useLDClient } from "launchdarkly-react-client-sdk";
import Departments from "~/routes/Department/Departments";
import ProblemVoiceButton from "../Voice/ProblemVoiceButton";

const Container = styled(FadeIn)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  line-height: 24px;
  color: #d1d5db;
  font-size: 16px;
  overflow: hidden;
`;

const InputContainer = styled(FadeIn)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 1000px;
  margin: 3rem auto;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 1.5rem;
  @media (max-width: 600px) {
    grid-gap: 0rem;
  }
`;

const Gutter = styled.div`
  width: 100px;
  @media (max-width: 600px) {
    width: 3px;
  }
`;

export default function ChatIntroPage() {
  const { authUser } = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();
  const { createChat } = useChats();
  const ldClient = useLDClient();
  const [department, setDepartment] = useState<string>();

  async function onSelect(departmentId: string | undefined) {
    setDepartment(departmentId);
  }

  async function createChatFromMessage(message: string) {
    ldClient!.variation("new-chat-text");
    ldClient!.track("chat-created");

    await ldClient!.flush();

    const id = await createChat({
      id: uuid(),
      userId: authUser!.uid,
      messages: [
        {
          id: uuid(),
          role: Role.USER,
          content: message,
          errorMessage: null,
          model: null,
          platform: null,
          previousMessageId: null,
          updatedAt: new Date() as any,
          idempotencyKey: null,
        },
      ],
      files: {},
      updatedAt: serverTimestamp() as Timestamp,
      status: ChatStatus.REQUESTED,
      departmentId: user.departmentId!,
    });

    navigate("/chat/" + id);
  }

  async function createChatFromVoice() {
    ldClient!.variation("new-chat-text");
    ldClient!.track("chat-created");

    await ldClient!.flush();

    const id = await createChat({
      id: uuid(),
      userId: authUser!.uid,
      messages: [],
      files: {},
      updatedAt: serverTimestamp() as Timestamp,
      status: ChatStatus.READY,
      departmentId: user.departmentId!,
    });

    navigate("/chat/" + id + "?voice=true");
  }

  if (!department) {
    return <Departments onSelect={onSelect} />;
  }

  return (
    <Container translate={false}>
      <IntroSection />
      <InputContainer>
        <Gutter />
        <InputPrompt submitMessage={createChatFromMessage} />
        {user.departmentId === "problem" && (
          <ProblemVoiceButton startChat={createChatFromVoice} />
        )}
        <Gutter />
      </InputContainer>
    </Container>
  );
}
