import React from "react";
import { styled } from "styled-components";

/**
 * Transient props are required to avoid invalid attributes on DOM elements.
 */
interface ButtonWrapperProps {
  $selected?: boolean;
  $block?: boolean;
}

const ButtonWrapper = styled.button<ButtonWrapperProps>`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 30px;
  color: white;
  text-align: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 18.9px;
  border: 1.89px solid #5a5a5a;
  background: ${({ $selected }) => ($selected ? "#98F4CC" : "#1d1d1f")};
  width: ${({ $block }) => ($block ? "100%" : "auto")};
  cursor: pointer;

  &:hover {
    background: #98F4CC;
    transition: all 0.3s ease-in-out;
`;

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  svg?: React.ReactNode;
  block?: boolean;
  selected?: boolean;
  type?: "button" | "submit";
  disabled?: boolean;
}

const Button = ({
  svg,
  onClick,
  block,
  selected,
  children,
  ...props
}: Props) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      $block={block}
      $selected={selected}
      {...props}
    >
      {svg}
      {children}
    </ButtonWrapper>
  );
};

export default Button;
