// This file is generated by locala. Do not edit.
// Source: locala.yaml

import { strings as en } from "./en";
import { strings as de_DE } from "./de_DE";

export type LocaleStrings_en = keyof typeof en;
export type LocaleStrings_de_DE = keyof typeof de_DE;

export type LocaleStrings =
    | LocaleStrings_en
    | LocaleStrings_de_DE

export type Locale =
    | "en"
    | "de_DE"

export const locales = {
"en": en,
"de_DE": de_DE,
} as const