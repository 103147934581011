import { Timestamp } from "firebase/firestore";

export interface ChatFile {
  updatedAt: Timestamp;
}

export interface Chat {
  id: string;
  status: ChatStatus;
  updatedAt: Timestamp;
  userId: string;
  departmentId: string;
  messages: ChatMessage[];
  files: Record<string, ChatFile> | null;
}

export interface Actions {
  id: string;
  name: string;
  input: string;
  completed: boolean;
}

export interface ChatMessage {
  id: string;
  previousMessageId: string | null;
  updatedAt: Timestamp;
  role: Role;
  content: string | null;
  errorMessage: string | null;
  platform: Platform | null;
  model: Model | null;
  idempotencyKey: string | null;
  rating?: Rating; // Add rating field
  actions?: Actions[] | null;
}

export enum RatingType {
  LIKE = 1,
  DISLIKE = -1,
}

export interface Rating {
  updatedAt: Timestamp;
  value: RatingType;
  feedbackText: string | null;
}

export enum Platform {
  OPENAI = "ChatOpenAI",
}

export enum Model {
  GPT_4 = "gpt-4",
  GPT_4_32K = "gpt-4-32k",
  GPT_3_5_TURBO = "gpt-3.5-turbo",
}

export enum Role {
  USER = "USER",
  AI = "AI",
  SYSTEM = "SYSTEM",
}

export enum ChatStatus {
  REQUESTED = "REQUESTED",
  READY = "READY",
  REGENERATE = "REGENERATE",
  STREAMING = "STREAMING",
  ERROR = "ERROR",
}
