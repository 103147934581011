import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

import {
  collection,
  CollectionReference,
  doc,
  query,
  updateDoc,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "~/integrations/firebase/firestore";
import { File, FileStatus } from "~/types/File";
import { useAuth } from "./AuthContext";

interface FilesContext {
  files: File[];
  loading: boolean;
  error?: Error;
  reference: CollectionReference<File>;
}

export const FilesContext = createContext<FilesContext>(undefined as never);

export const useFiles = () => useContext(FilesContext);

export function useFile(id: string) {
  const context = useFiles();

  const { reference, files } = context;

  const file = files.find((file) => file.id === id);

  const uploadFile = useCallback(
    async (fileToUpload: Blob, abortSignal: AbortSignal) => {
      if (!file) {
        return {
          status: "ERROR",
          message: "Unable to upload file, file not found",
        };
      }

      if (file.status !== FileStatus.UPLOAD_URL_GENERATED) {
        return {
          status: "ERROR",
          message: `Unable to upload file, status is not UPLOAD_URL_GENERATED. Status: ${file.status}`,
        };
      }

      const fileReference = doc(reference, file.id);

      try {
        console.log("Uploading file", fileToUpload);

        const form = new FormData();

        form.append("file", fileToUpload);

        const url = file.uploadUrl;

        if (!url) {
          return {
            status: "ERROR",
            message: `Unable to upload file, no uploadUrl found`,
          };
        }

        await updateDoc(fileReference, {
          status: FileStatus.UPLOADING,
          updatedAt: serverTimestamp(),
        });

        const response = await fetch(url, {
          signal: abortSignal,
          method: "PUT",
          body: form,
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });

        console.log("Uploaded file", fileToUpload, response.status);

        if (!response.ok) {
          throw new Error(
            `Failure during upload to uploadUrl. Response: ${response.status}`,
          );
        }

        await updateDoc(fileReference, {
          status: FileStatus.UPLOADED,
          updatedAt: serverTimestamp(),
        });

        return {
          status: "SUCCESS",
        };
      } catch (e) {
        console.error("Error uploading file", e);

        await updateDoc(fileReference, {
          status: FileStatus.ERROR,
          updatedAt: serverTimestamp(),
          error: e.message,
        });

        return {
          status: "ERROR",
          message: e.message,
        };
      }
    },
    [file],
  );

  return {
    ...context,
    file,
    uploadFile,
  };
}

export const FilesProvider = ({ children }: PropsWithChildren) => {
  const { authUser } = useAuth();

  const reference = useMemo(
    () => collection(db, "files") as CollectionReference<File>,
    [],
  );

  const [files = [], loading, error] = useCollectionData<File>(
    query(reference, where("userId", "==", authUser!.uid)),
  );

  return (
    <FilesContext.Provider value={{ files, loading, error, reference }}>
      {children}
    </FilesContext.Provider>
  );
};
