import React from "react";
import { Modal } from "~/components/Modal/Modal";
import { styled } from "styled-components";
import {
  CloudUpload,
  EmptyPage,
  WarningCircle,
  CheckCircle,
} from "iconoir-react";

export const ModalContainer = styled(Modal)<{ $isActive: boolean }>`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  border: 2px solid
    ${({ $isActive }) =>
      $isActive ? "var(--noa-purple-highlight)" : "transparent"};
`;

export const Dashed = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  border-radius: 15px;
  transition: border-color 0.2s linear;

  border-width: 2px;
  border-style: dashed;
  border-color: rgba(157, 157, 157, 0.56);
`;

export const Drop = styled(Dashed)<{ $isActive: boolean }>`
  cursor: pointer;

  border-color: ${({ $isActive }) =>
    $isActive ? " blueviolet" : "rgba(157, 157, 157, 0.56)"};

  color: ${({ $isActive }) =>
    $isActive ? "var(--noa-purple-highlight)" : "white"};
`;

export const DropMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

export const OrUploadButton = styled.button`
  color: var(--noa-purple-hightlight);
  background: none;
  border: 0;
  font-weight: 400;
  font-size: 1rem;
  margin: 1rem 0;
  cursor: pointer;
`;

export const Title = styled.div`
  color: white;
  font-size: 1.25rem;
  margin-top: 2rem;
`;

export const Support = styled.div`
  color: #7f7f7f;
  font-size: 0.9rem;
  margin-top: 2rem;
  text-align: center;
`;

export const FileName = styled.div`
  font-size: 0.9rem;
  margin-top: 2rem;
  text-align: center;
`;

export const Message = styled.div`
  color: white;
  font-size: 0.9rem;
  margin-top: 2rem;
  text-align: center;
`;

export const FileTypes = styled.div`
  color: white;
  font-weight: 600;
  margin-top: 0.5rem;
`;

export const UploadIcon = styled(CloudUpload)`
  font-size: 5rem;
  color: inherit;
  transition: color 0.2s linear;
`;

export const DocumentIcon = styled(EmptyPage)`
  font-size: 5rem;
  color: var(--noa-purple-highlight);
  transition: color 0.2s linear;
`;

export const DocumentSuccessIcon = styled(CheckCircle)`
  font-size: 5rem;
  color: var(--noa-purple-highlight);
  transition: color 0.2s linear;
`;

export const DocumentErrorIcon = styled(EmptyPage)`
  font-size: 5rem;
  color: var(--noa-error);
`;

export const FailedIcon = styled(WarningCircle)`
  font-size: 5rem;
  color: var(--noa-error);
`;

export const Button = styled.button`
  border-radius: 12px;
  color: white;
  border: 0;
  background: var(--noa-gradient);
  min-width: 270px;
  min-height: 60px;
  font-size: 1rem;
  cursor: pointer;
  padding: 0 1rem;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Details = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
