import React from "react";
import { styled } from "styled-components";
import { FadeIn } from "~/components/FadeIn";
import { Text } from "~/components/Text";
import { useUser } from "~/context/UserContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LabsContainer } from "~/components/LabsAvatar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 5rem;
  overflow-y: auto;
`;

const Intro = styled(FadeIn)`
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
`;

const Prompt = styled(FadeIn)`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
`;

const Description = styled(FadeIn)`
  background: #212121;
  margin: 3rem auto;
  width: 30rem;
  border-radius: 12px;
  padding: 2rem 3rem;
`;

const DescriptionTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;

const DescriptionContent = styled.div`
  font-weight: 300;
  font-size: 0.85rem;
  text-align: center;
  margin-top: 1rem;
`;

const LabsAvatar = styled(LabsContainer)`
  height: 20vh;
  max-height: 30vh;
  min-height: 100px;
  width: 20vh;
  margin: 4rem auto 3rem;
`;

const IntroSection: React.FC = () => {
  const flags = useFlags();
  const { user } = useUser();

  let greetingsText = "Hello!";
  let greetingsQuestion = "How can I assist you? 🧠💡";
  switch (user.departmentId) {
    case "problem":
      greetingsText = "Hello, let's define a problem! 🐼";
      greetingsQuestion =
        "Either text-chat with the Problem Panda to define a problem 📝, or use your voice to describe the problem you are trying to solve. 🗣️";
      break;
    case "prd":
      greetingsText = "Hello, let's create a Product Requirements Document!";
      greetingsQuestion = "What feature or product would you like to build?";
      break;
    case "okr":
      greetingsText = "Hello, let's define OKRs together - yay! 🎯";
      greetingsQuestion = "Which objective would you like to start with?";
  }

  return (
    <Container>
      <LabsAvatar />
      <Intro data-testid="intro-section-title">{greetingsText}</Intro>
      <Prompt delay={100}>{greetingsQuestion}</Prompt>
    </Container>
  );
};

export default IntroSection;
