import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import React from "react";

import {useAuth} from "~/context/AuthContext";
import Login from "~/routes/Login";
import ChatRoute from "~/routes/Chat/ChatRoute";
import {UserProvider} from "~/context/UserContext";
import {DepartmentsProvider} from "~/context/DepartmentsContext";
import {lazyRoute} from "~/routes/lazyRoute";
import LogoutPage from "~/routes/Logout";
import {FilesProvider} from "~/context/FilesContext";
import {LDProvider} from "launchdarkly-react-client-sdk";

const Department = React.lazy(
    () => import("~/routes/Department/DepartmentPage"),
);

function AppRoutes() {
    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>

            <Route path="*" element={<AuthLayout/>}>
                <Route path="logout" element={<LogoutPage/>}/>
                <Route path="department" element={lazyRoute(<Department/>)}/>
                <Route path="*" element={<ChatRoute/>}/>
            </Route>
        </Routes>
    );
}

const AuthLayout = () => {
    const {authUser, loading} = useAuth();

    if (loading) {
        return null; // TODO: Add loading screen
    }

    if (!authUser) {
        console.log("Redirecting to login");
        return <Navigate to="/login"/>;
    }
    console.log(authUser.uid);

    return (
        <LDProvider
            clientSideID="6520478f45ff0312f92e04b3"
            context={{
                kind: "user",
                key: authUser.uid,
                name: authUser.displayName || undefined,
                email: authUser.email,
            }}
        >
            <DepartmentsProvider>
                <UserProvider userId={authUser.uid}>
                    <FilesProvider>
                        <Outlet/>
                    </FilesProvider>
                </UserProvider>
            </DepartmentsProvider>
        </LDProvider>
    );
};

export default AppRoutes;
