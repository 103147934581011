import { init, BrowserTracing, Replay } from "@sentry/react";

if (!import.meta.env.DEV) {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      new BrowserTracing({ tracePropagationTargets: ["localhost"] }),
      new Replay(),
    ],
    // TODO: Review tracesSampleRate in production
    tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
