import { PropsWithChildren, MouseEvent, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { css, styled } from "styled-components";

const Container = styled(Transition)<{
  $delay?: number;
  $speed?: number;
  $translate?: boolean;
}>`
  transition: all ${({ $speed }) => $speed}s cubic-bezier(0.33, 1, 0.68, 1);

  // Add a delay to the transition if the delay prop is set
  transition-delay: ${({ $delay }) =>
    `${$delay === 0 ? 0 : ($delay ?? 0) + 250}ms`};

  &.hide {
    opacity: 0;

    transform: ${({ $translate }) =>
      $translate ? "translateY(50px)" : "translateY(0px)"};
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface Props {
  delay?: number;
  className?: string;
  translate?: boolean;
  speed?: number;

  onClick?(event: MouseEvent<HTMLElement>): void;
}

export function FadeIn({
  delay,
  translate = true,
  speed = 0.8,
  ...props
}: PropsWithChildren<Props>) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  return (
    <Container
      show={show}
      enterFrom="hide"
      enterTo="show"
      leaveFrom="show"
      leaveTo="hide"
      $speed={speed}
      $translate={translate}
      $delay={delay}
      {...props}
    />
  );
}
