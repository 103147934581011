import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, DocumentReference, setDoc } from "firebase/firestore";

import { db } from "~/integrations/firebase/firestore";
import { User } from "~/types/User";
import { useAnalytics } from "~/context/AnalyticsProvider";

interface UserContext {
  user: User;

  setDepartment(id: string): Promise<void>;
}

export const UserContext = createContext<UserContext>(undefined as never);

export const useUser = () => useContext(UserContext);

interface Props {
  userId: string;
}

export const UserProvider = ({
  children,
  userId,
}: PropsWithChildren<Props>) => {
  const reference = doc(db, "users", userId) as DocumentReference<User>;
  const [user, loading, error] = useDocumentData<User>(reference);
  const { setUserDetails } = useAnalytics();

  useEffect(() => {
    setUserDetails({ departmentId: user?.departmentId });
  }, [user?.departmentId]);

  if (loading) {
    return null;
  }

  async function setDepartment(departmentId: string) {
    return await setDoc(reference, { userId, departmentId }, { merge: true });
  }

  return (
    <UserContext.Provider value={{ user: user ?? { userId }, setDepartment }}>
      {children}
    </UserContext.Provider>
  );
};
