import React from "react";
import Button from "~/components/Button";
import { styled } from "styled-components";
import { useDepartments } from "~/context/DepartmentsContext";
import { Department } from "~/types/Department";
import { useUser } from "~/context/UserContext";
import { FadeIn } from "~/components/FadeIn";
import { Text } from "~/components/Text";
import { LabsContainer } from "~/components/LabsAvatar";
import { useAnalytics } from "~/context/AnalyticsProvider";

const Container = styled(FadeIn)`
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  padding: 8rem 2rem;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled(FadeIn)`
  font-size: 1.625rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;

const Description = styled(FadeIn)`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.68rem;
  margin-bottom: 2rem;
`;

const ButtonContainer = styled(FadeIn)`
  flex-basis: 30%;
`;

const Row = styled(FadeIn)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 200px;
  gap: 12px;
`;

const LabsAvatar = styled(LabsContainer)`
  position: absolute;
  top: 400px;
  width: 100%;
  height: 1000px;
`;

interface Props {
  onSelect(id: string): void;
}

function Departments({ onSelect }: Props) {
  const { log } = useAnalytics();
  const { setDepartment, user } = useUser();

  const { departments, loading } = useDepartments();

  async function onClick(department: Department) {
    log({
      type: "department_selected",
      payload: {
        departmentId: department.id,
        departmentTitle: department.title,
        previousDepartmentId: user.departmentId,
      },
    });

    await setDepartment(department.id);

    onSelect(department.id);
  }

  return (
    <>
      <Container>
        <Title>
          <Text id="departments.title" />
        </Title>

        <Description delay={100}>
          <Text id="departments.subtitle" multiline />
        </Description>

        <Row data-testid="departments-list" delay={200}>
          {departments.map((department) => (
            <ButtonContainer
              key={department.id}
              data-testid="departments-list-item"
            >
              <Button
                block
                style={{ width: "100%" }}
                onClick={() => onClick(department)}
              >
                {department.title}
              </Button>
            </ButtonContainer>
          ))}
        </Row>
      </Container>

      {!loading && <LabsAvatar />}
    </>
  );
}

export default Departments;
