import React, { useContext, useEffect, useRef } from "react";
import { styled } from "styled-components";
import {
  AvatarAnimationContext,
  useAvatar,
} from "~/context/AvatarAnimationContext";
import useIsSmallDevice from "~/hooks/useIsSmallDevice";
import { useLayout } from "~/context/LayoutContext";

const Container = styled.div`
  pointer-events: none;
`;

interface Props {
  className?: string;
  opacity?: number;
}

export function LabsContainer({ className, opacity }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const avatar = useAvatar();
  const { isSmallDevice } = useIsSmallDevice();
  const { showLeftDrawer } = useLayout();

  useEffect(() => {
    function updatePosition() {
      if (!ref.current) {
        return;
      }

      const position = ref.current.getBoundingClientRect();

      const { top, left, height, width } = position;
      const x = left + width / 2 - height / 2;

      avatar.set({
        y: top,
        x,
        size: height,
        opacity: opacity ?? 1,
      });
    }

    // Delay update to allow for drawer animation
    setTimeout(updatePosition, 250);

    window.addEventListener("resize", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, [isSmallDevice, showLeftDrawer, ref.current]);

  return <Container className={className} ref={ref} />;
}
