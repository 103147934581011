import React, { useEffect, useState } from "react";
import { Text } from "~/components/Text";
import {
  Button,
  Content,
  Dashed,
  Details,
  DocumentIcon,
  DocumentSuccessIcon,
  FileName,
  Support,
  Title,
} from "./styled";
import { useChat } from "~/context/ChatContext";
import { ProgressBar } from "~/components/ProgressBar";
import { DocumentReference } from "firebase/firestore";
import { FileStatus } from "~/types/File";
import { useFile } from "~/context/FilesContext";

interface UploadingScreenProps {
  files: File[];

  setError(error: string): void;

  onClose(): void;
}

export function UploadingScreen({
  files,
  setError,
  onClose,
}: UploadingScreenProps) {
  const { createFileUploadRequest } = useChat();

  const [reference, setReference] = useState<DocumentReference>();

  async function onUpload() {
    if (!files.length) {
      console.error("No files to upload");
      return;
    }

    // Only support one file for now
    const [file] = files;
    try {
      const reference = await createFileUploadRequest(file.name, file.type);

      setReference(reference);
    } catch (e) {
      console.error("Failed to create file upload request");
      console.error(e);

      setError("Failed to create file upload request");
    }
  }

  if (reference) {
    return (
      <FileUploadProgress
        file={files[0]}
        reference={reference}
        setError={setError}
        onClose={onClose}
      />
    );
  }

  return (
    <Content>
      <Details>
        <DocumentIcon />

        <Title>{files.map((file) => file.name).join(", ")}</Title>

        <Support>
          <Text id="files.upload.attached.note" multiline />
        </Support>
      </Details>

      <Button onClick={onUpload}>
        <Text id="files.upload.attached.upload-button" />
      </Button>
    </Content>
  );
}

interface FileUploadProgressProps {
  file: File;
  reference: DocumentReference;

  setError(error: string): void;

  onClose(): void;
}

function FileUploadProgress({
  file: fileToUpload,
  reference,
  setError,
  onClose,
}: FileUploadProgressProps) {
  const { file, uploadFile } = useFile(reference.id);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!file) {
      return;
    }

    const { status } = file;
    const progress = getProgress(status);

    if (progress === null) {
      return;
    }

    setProgress(progress);
  }, [file?.status]);

  useEffect(() => {
    if (!file?.uploadUrl) {
      return;
    }

    const controller = new AbortController();

    async function upload() {
      try {
        const result = await uploadFile(fileToUpload, controller.signal);

        if (result.status === "ERROR") {
          console.error(result.message);
          return;
        }
      } catch (e) {
        console.error("Failed to upload file");
        console.error(e);

        setError("Failed to upload file");
      }
    }

    upload().then();

    return () => {
      controller.abort();
    };
  }, [file?.uploadUrl, fileToUpload]);

  useEffect(() => {
    if (file?.status !== FileStatus.ERROR) {
      return;
    }

    setError(file.error?.message ?? "Failed to upload file");
  }, [file?.status]);

  if (file?.status === FileStatus.PROCESSED) {
    return (
      <Content>
        <Details>
          <DocumentSuccessIcon />

          <Title>
            <Text id="files.upload.finished.title" />
          </Title>

          <Support>
            <Text id="files.upload.finished.message" />
          </Support>

          <FileName>{file.fileName}</FileName>
        </Details>

        <Button onClick={onClose}>
          <Text id="files.upload.finished.return-button" />
        </Button>
      </Content>
    );
  }

  return (
    <>
      <Dashed>
        <Details>
          <ProgressBar progress={progress} />

          <Title>
            {file?.status === FileStatus.UPLOADED ? (
              <Text id="files.uploading.status.processing" />
            ) : (
              <Text id="files.uploading.status.uploading" />
            )}
          </Title>

          <Support>
            <Text id="files.uploading.subtitle" multiline />
          </Support>
        </Details>
      </Dashed>
    </>
  );
}

const stages: FileStatus[] = [
  FileStatus.REQUESTED,
  FileStatus.UPLOAD_URL_GENERATED,
  FileStatus.UPLOADING,
  FileStatus.UPLOADED,
  // FileStatus.PROCESSED,
];

function getProgress(status: FileStatus) {
  const index = stages.indexOf(status) + 1;

  if (index === -1) {
    return null;
  }

  return (index / stages.length) * 95;
}
