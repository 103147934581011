import styled from "styled-components";
import React, { PropsWithChildren } from "react";
import { FadeIn } from "~/components/FadeIn";
import { XIcon } from "~/components/Icons/XIcon";

const Container = styled(FadeIn)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.56);
  z-index: 100;
  overflow-y: auto;
`;

const Content = styled(FadeIn)`
  margin: 5rem auto 5rem;
  background: var(--primary-bg-color);
  border-radius: 14px;
  height: 770px;
  width: 770px;
  color: white;
  box-sizing: border-box;
  position: relative;
`;

const CloseButton = styled(XIcon)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

interface Props {
  onClose(): void;

  show: boolean;
}

export function Modal({
  show,
  onClose,
  children,
  ...props
}: PropsWithChildren<Props>) {
  function onOverlayClick(event: React.MouseEvent) {
    if (event.target === event.currentTarget) {
      onClose();
    }
  }

  if (!show) {
    return;
  }

  return (
    <Container delay={0} translate={false} onClick={onOverlayClick}>
      <Content delay={0} speed={0.2} {...props}>
        <CloseButton onClick={() => onClose()} />
        {children}
      </Content>
    </Container>
  );
}
