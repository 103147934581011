import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface LayoutContext {
  showLeftDrawer: boolean;
  showRightDrawer: boolean;

  toggleLeftDrawer(): void;

  toggleRightDrawer(): void;

  setShowLeftDrawer(show: boolean): void;

  setShowRightDrawer(show: boolean): void;
}

export const LayoutContext = createContext<LayoutContext>(undefined as never);

export const useLayout = () => useContext(LayoutContext);

export const LayoutContextProvider = ({ children }: PropsWithChildren) => {
  const [showLeftDrawer, setShowLeftDrawer] = useState(false);
  const [showRightDrawer, setShowRightDrawer] = useState(false);

  const toggleLeftDrawer = () => setShowLeftDrawer(!showLeftDrawer);
  const toggleRightDrawer = () => setShowRightDrawer(!showRightDrawer);

  return (
    <LayoutContext.Provider
      value={{
        showLeftDrawer,
        showRightDrawer,
        toggleLeftDrawer,
        toggleRightDrawer,
        setShowLeftDrawer,
        setShowRightDrawer,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
