import React, { useContext, useEffect } from "react";
import { styled } from "styled-components";
import useIsSmallDevice from "~/hooks/useIsSmallDevice";
import { LayoutContext } from "~/context/LayoutContext";
import { ToggleSidebarButton } from "~/components/Sidebar/ToggleSidebarButton";
import BurgerIconSVG from "~/components/Icons/burgericon.svg";
import { useAnalytics } from "~/context/AnalyticsProvider";

const BurgerIcon = styled.button`
  z-index: 1;
  background: none;
  border: none;
  cursor: pointer;

  img {
    width: 2.5rem;
    height: 2.5rem;
    color: white;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 1rem;
  position: fixed;
  z-index: 10;
  right: 0;
  left: 0;
  top: 0;
`;

export function ChatHeader() {
  const {
    showLeftDrawer,
    showRightDrawer,
    setShowLeftDrawer,
    setShowRightDrawer,
  } = useContext(LayoutContext);

  const { isSmallDevice } = useIsSmallDevice();
  const { log } = useAnalytics();

  useEffect(() => {
    setShowLeftDrawer(!isSmallDevice);
  }, [isSmallDevice]);

  function onToggleSidebar(showLeftDrawer: boolean) {
    log({
      type: "sidebar_toggle_clicked",
      payload: {
        closed: !showLeftDrawer,
      },
    });

    setShowLeftDrawer(showLeftDrawer);
  }

  function onToggleSettingsMenu(showRightDrawer: boolean) {
    log({
      type: "settings_menu_toggle_clicked",
      payload: {
        closed: !showRightDrawer,
      },
    });

    setShowRightDrawer(showRightDrawer);
  }

  return (
    <Container>
      <ToggleSidebarButton onClick={() => onToggleSidebar(!showLeftDrawer)} />

      <BurgerIcon
        data-testid="menu-button"
        onClick={() => onToggleSettingsMenu(!showRightDrawer)}
      >
        <img src={BurgerIconSVG} alt="Menu" />
      </BurgerIcon>
    </Container>
  );
}
