import ReactMarkdown from "react-markdown";
import React from "react";
import { css, styled } from "styled-components";

interface Props {
  content: string;
  withCursor?: boolean;
}

const Container = styled.div<{ $withCursor?: boolean }>`
  p,
  blockquote {
    margin: 0 0 1rem;
  }

  blockquote {
    padding: 0.5rem 0.75rem;
    border-left: 4px solid var(--noa-gray-3);
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
  }

  ol,
  ul {
    margin-bottom: 1rem;
  }

  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  li {
    margin-bottom: 0.5rem;
  }

  code {
    background: var(--noa-black);
    white-space: pre-wrap;
    border-radius: 0.25rem;
    background: var(--noa-black);
    padding: 0.25rem 0.25rem;
    font-size: 0.8rem;
  }

  // Code block
  & > code {
    margin-bottom: 1rem;
    display: block;
    padding: 0.25rem 0.5rem;
  }

  img {
    max-width: 100%;
    border-radius: 0.25rem;
  }

  *:last-child {
    margin-bottom: 0;
  }

  @-webkit-keyframes blink {
    to {
      visibility: hidden;
    }
  }

  ${({ $withCursor }) =>
    $withCursor &&
    css`
      & > :not(ol):not(ul):not(pre):last-child:after,
      & > ol:last-child li:last-child:after,
      & > pre:last-child code:after,
      & > ul:last-child li:last-child:after {
        -webkit-animation: blink 1s steps(5, start) infinite;
        animation: blink 1s steps(5, start) infinite;
        content: "▋";
        margin-left: 0.25rem;
        vertical-align: baseline;
      }
    `}
`;

export function FormattedMessage({ content, withCursor }: Props) {
  return (
    <Container $withCursor={withCursor}>
      <ReactMarkdown
        children={content}
        allowedElements={[
          "p",
          "b",
          "i",
          "strong",
          "li",
          "ol",
          "ul",
          "em",
          "a",
          "code",
          "img",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "table",
          "blockquote",
        ]}
        unwrapDisallowed
        linkTarget="_blank"
      />
    </Container>
  );
}
