import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";
import { differenceInSeconds } from "date-fns";
import { InputPrompt } from "~/components/InputPrompt";
import { ChatItem } from "~/components/ChatItem";
import { useChat } from "~/context/ChatContext";
import { Chat, ChatStatus, Role } from "~/types/Chat";
import Button from "~/components/Button";
import { FileUploadModal } from "~/components/FileUploadModal";
import { CloudError, Upload } from "iconoir-react";
import { useApplication } from "~/context/ApplicationContext";
import { Timestamp } from "firebase/firestore";
import { throttle } from "lodash";
import { useInterval } from "~/hooks/useInterval";
import { useAnalytics } from "~/context/AnalyticsProvider";
import ProblemVoiceButton from "../Voice/ProblemVoiceButton";

const Container = styled.div`
  flex: 1;
  overflow-y: scroll;
  margin: 0 0.5rem 7.5rem;
  @media (max-width: 600px) {
    margin: 0 0 0;
  }
`;

const Messages = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  line-height: 24px;
  color: #d1d5db;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  padding: 6rem 0 2rem;
  @media (max-width: 600px) {
    width: 100%;
    padding: 0 0 7rem;
  }
`;

const InputContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 1000px;
  margin: 1.5rem auto;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 1.5rem;
  @media (max-width: 600px) {
    grid-gap: 0rem;
  }
`;

const Gradient = styled.div`
  height: 10rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 2rem;
  z-index: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    var(--primary-bg-color) 20%,
    var(--primary-bg-color) 100%
  );
  @media (max-width: 600px) {
    right: 0;
  }
`;

const UploadButton = styled(Button)`
  gap: 5px;
  height: 100%;
  border-width: 2px;
`;

const Gutter = styled.div`
  width: 100px;
  @media (max-width: 600px) {
    width: 1px;
  }
`;

const ErrorMessageContainer = styled.div`
  display: flex;
`;

const ErrorMessage = styled.div`
  max-width: 1000px;
  border-radius: 0.5rem;
  margin-left: 130px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #9e9e9e;

  svg {
    color: #ff0000;
    margin-right: 0.5rem;
  }

  button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }
`;

export default function ChatPage() {
  const { chat, addMessage, addVoiceMessage } = useChat();
  const messagesRef = useRef<HTMLDivElement>(null);
  const [showFileModal, setShowFileModal] = useState(false);
  const { features } = useApplication();
  const { log } = useAnalytics();

  const shouldScrollToBottom = useRef(true);
  const scrollY = useRef(0);

  // Scroll on load and update state when user scrolls
  useEffect(() => {
    const onScroll = throttle((event: Event) => {
      const element = event.target as HTMLDivElement;

      // If user scrolls up then the chat should not scroll to bottom
      if (scrollY.current > element.scrollTop) {
        shouldScrollToBottom.current = false;
      }

      const scrollBottom = element.scrollHeight - element.clientHeight;
      const isScrolledToBottom = element.scrollTop >= scrollBottom - 100;

      // If the user has scrolled into the bottom 100px then the chat should scroll to bottom
      if (isScrolledToBottom) {
        shouldScrollToBottom.current = true;
      }

      scrollY.current = element.scrollTop;
    }, 100);

    if (messagesRef.current) {
      messagesRef.current.scrollTo({
        top: messagesRef.current.scrollHeight,
      });

      messagesRef.current.addEventListener("scroll", onScroll);
    }

    return () => {
      messagesRef.current?.removeEventListener("scroll", onScroll);
    };
  }, [chat.id, messagesRef.current]);

  function scrollToBottom(force: boolean = false) {
    if (!messagesRef.current) {
      return;
    }

    // If the user has scrolled up then don't scroll to bottom
    if (!shouldScrollToBottom.current && !force) {
      return;
    }

    messagesRef.current?.scrollTo({
      behavior: "smooth",
      top: messagesRef.current?.scrollHeight,
    });
  }

  // Throttle the scroll to bottom function, so it doesn't get called too often
  const onScrollBottomHandler = useCallback(throttle(scrollToBottom, 300), []);

  const [secondsSinceLastUpdate, setSecondsSinceLastUpdate] = useState(0);

  useInterval(
    () => setSecondsSinceLastUpdate(getSecondsSinceLastUpdate(chat)),
    1000,
  );

  const errorMessage = useMemo(() => {
    if (!chat) {
      return null;
    }

    const { status } = chat;

    // Labs has errored, display a generic error until `chat.errorMessage` is implemented
    if (status === ChatStatus.ERROR) {
      return "Apologies, Labs couldn't get a response";
    }

    // Labs is not performing any action
    if (status === ChatStatus.READY) {
      return null;
    }

    // There's no updatedAt, so we're unable to determine the time since last update
    if (!chat.updatedAt) {
      return null;
    }

    // Labs hasn't taken a long time so far.
    if (secondsSinceLastUpdate < 30) {
      return null;
    }

    if (secondsSinceLastUpdate < 60 * 5) {
      return null;
    }

    // Labs has taken over 5 minutes, there's a good chance he's probably not going to respond
    return "Apologies, Labs couldn't get a response.";
  }, [chat, secondsSinceLastUpdate]);

  useEffect(() => {
    if (!errorMessage) {
      return;
    }

    log({
      type: "chat_error_shown",
      payload: {
        chatId: chat.id,
        departmentId: chat.departmentId,
        errorMessage,
        latestMessageId: messages[messages.length - 1]?.id,
      },
    });
  }, [errorMessage]);

  const isLoading = [
    ChatStatus.REQUESTED,
    ChatStatus.REGENERATE,
    ChatStatus.STREAMING,
  ].includes(chat.status);

  const messages = useMemo(() => {
    const filtered = chat.messages.filter(
      (message) => message.role != Role.SYSTEM,
    );

    if (!filtered?.length || !isLoading) {
      return filtered;
    }

    if (errorMessage) {
      return filtered;
    }

    if (filtered[filtered.length - 1]?.role === Role.AI) {
      return filtered;
    }

    // If it's loading and the last message is not AI, then mimic the message until it is created
    return [
      ...filtered,
      {
        role: Role.AI,
        content: "",
        id: "loading",
        errorMessage: null,
        model: null,
        platform: null,
        previousMessageId: null,
        updatedAt: Timestamp.fromDate(new Date()),
        idempotencyKey: null,
      },
    ];
  }, [chat.messages, isLoading, errorMessage]);

  const isReady = chat.status === ChatStatus.READY;

  const submitMessage = async (message: string) => {
    const previousMessageId = chat.messages[chat.messages.length - 1]?.id;
    await addMessage(message, previousMessageId);

    scrollToBottom(true);
  };

  return (
    <>
      {showFileModal && (
        <FileUploadModal onClose={() => setShowFileModal(false)} />
      )}

      <Container ref={messagesRef} data-testid="asdf">
        <Messages>
          {messages.map((message, index) => (
            <ChatItem
              key={index}
              message={message}
              isLoading={isLoading}
              isLatestMessage={index === messages.length - 1}
              onMessageContentChange={onScrollBottomHandler}
            />
          ))}

          {errorMessage && (
            <ErrorMessageContainer>
              <ErrorMessage>
                <CloudError />
                <div>{errorMessage}</div>
              </ErrorMessage>
            </ErrorMessageContainer>
          )}
        </Messages>
      </Container>

      <Gradient />

      <InputContainer data-testid="inputcontainer">
        <Gutter />
        <InputPrompt submitMessage={submitMessage} disabled={!isReady} />
        <Gutter>
          {features?.fileUploadEnabled && (
            <UploadButton onClick={() => setShowFileModal(true)}>
              <Upload />
              Upload
            </UploadButton>
          )}
          {chat.departmentId === "problem" && (
            <ProblemVoiceButton addVoiceMessage={addVoiceMessage} chat={chat} />
          )}
        </Gutter>
      </InputContainer>
    </>
  );
}

function getSecondsSinceLastUpdate(chat: Chat) {
  if (chat.status === ChatStatus.READY || !chat.updatedAt) {
    return 0;
  }

  return differenceInSeconds(new Date(), chat.updatedAt.toDate());
}
