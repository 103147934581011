import React, { useContext } from "react";
import styled from "styled-components";
import { MenuItem } from "~/components/BurgerMenu/MenuItem";
import FAQIcon from "../Icons/FAQ.svg";
import FeedbackIcon from "../Icons/contact.svg";
import LogoutIcon from "../Icons/logout.svg";
import { XIcon } from "~/components/Icons/XIcon";
import { LayoutContext } from "~/context/LayoutContext";
import { useApplication } from "~/context/ApplicationContext";
import { useAnalytics } from "~/context/AnalyticsProvider";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0;
`;

const MenuTitle = styled.div`
  font-size: 1.2rem;
  color: var(--primary-text-color);
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButtonIcon = styled(XIcon)`
  width: 1.25rem;
  height: 1.25rem;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  border-radius: 18px 0px 18px 0px;
  padding: 1.75rem 0;
  background-color: var(--secondary-bg-color);
  flex: 1;
  overflow: hidden;
  height: calc(100vh - 5px);
  width: 300px;

  @media (max-width:600px) { 
    width: 100%;
  }
`;

export function BurgerMenu() {
  const { setShowRightDrawer } = useContext(LayoutContext);
  const { links } = useApplication();
  const { log } = useAnalytics();

  const items = [
    {
      id: "help-centre",
      title: "Help Centre",
      to: links?.helpCentre as string,
      icon: FAQIcon,
      rel: "noreferrer",
      target: "_blank",
    },
    {
      id: "feedback",
      title: "Submit Feedback",
      to: links?.feedback as string,
      icon: FeedbackIcon,
      rel: "noreferrer",
      target: "_blank",
    },
    {
      id: "logout",
      title: "Log out",
      to: "/logout",
      icon: LogoutIcon,
    },
  ].filter((item) => item.to);

  function onHideMenu() {
    log({
      type: "settings_menu_toggle_clicked",
      payload: {
        closed: true,
      },
    });

    setShowRightDrawer(false);
  }

  function onMenuItemClicked(item: (typeof items)[0]) {
    log({
      type: "settings_menu_item_clicked",
      payload: {
        linkTitle: item.title,
        link: item.to,
      },
    });
  }

  return (
    <Container>
      <HeaderWrapper>
        <CloseButton onClick={onHideMenu}>
          <CloseButtonIcon />
        </CloseButton>

        <MenuTitle>Help & Settings</MenuTitle>
      </HeaderWrapper>

      {items.map((item) => (
        <MenuItem
          key={item.title}
          to={item.to}
          icon={item.icon}
          rel={item.rel}
          target={item.target}
          data-testid={`menu-item-${item.id}`}
          onClick={() => onMenuItemClicked(item)}
        >
          {item.title}
        </MenuItem>
      ))}
    </Container>
  );
}
