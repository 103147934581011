import React from "react";
import { styled } from "styled-components";
import { useAvatar } from "~/context/AvatarAnimationContext";

const Container = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1;
  pointer-events: none;
`;

/**
 * Transient props are required to avoid invalid attributes on DOM elements.
 */
interface AvatarProps {
  $x: number;
  $y: number;
  $size: number;
  $opacity: number;
}

const Avatar = styled.div<AvatarProps>`
  position: absolute;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  top: ${({ $y }) => $y}px;
  left: ${({ $x }) => $x}px;
  opacity: ${({ $opacity }) => $opacity};
  transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
`;

const Bounce = styled.div`
  animation: 2s ease-in-out 0s infinite alternate none running bob;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @keyframes bob {
    0% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(10px);
    }
  }
`;

const Body = styled.div`
  background: linear-gradient(180deg, #98F4CC 0%, #030E09 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10000px;
`;

const Glow = styled.div<{ $size: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10000px;
  background: transparent;
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: 0 0 ${({ $size }) => `${$size / 2}px`} #98F4CC;
  animation: 5s ease-in-out 0s infinite alternate none running pulse;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.75);
    }
  }
`;

const LabsAvatar = () => {
  const avatar = useAvatar();

  const { x, y, size, opacity } = avatar.state;

  return (
    <Container>
      <Avatar $size={size} $x={x} $y={y} $opacity={opacity}>
        <Bounce>
          <Glow $size={size}></Glow>
          <Body></Body>
        </Bounce>
      </Avatar>
    </Container>
  );
};

export default LabsAvatar;
