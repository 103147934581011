import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from "react";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { analytics } from "~/integrations/firebase/analytics";
import { useApplication } from "~/context/ApplicationContext";
import { entries, fromPairs, snakeCase } from "lodash";

interface ApplicationContext {
  log(event: AnalyticsEvent): void;

  setUser(id?: string | null): void;

  setUserDetails(properties: UserProperties): void;
}

interface UserProperties {
  departmentId?: string;
  organisationId?: string;
}

export const AnalyticsContext = createContext<ApplicationContext>(
  undefined as never,
);

export const useAnalytics = () => useContext(AnalyticsContext);

export function AnalyticsProvider({ children }: PropsWithChildren) {
  const { features } = useApplication();

  const log = useCallback(
    (event: AnalyticsEvent) => {
      if (!features.analyticsEnabled) {
        return;
      }

      try {
        const payload = entries("payload" in event ? event.payload : undefined);
        const mapped = payload.map(([key, value]) => [snakeCase(key), value]);
        const params = fromPairs(mapped);

        logEvent(analytics, event.type, params);
      } catch (e) {
        console.log("Unable to track event", event, e);
      }
    },
    [features.analyticsEnabled],
  );

  const setUser = useCallback(
    (userId: string | null) => {
      if (!features.analyticsAuthEnabled) {
        return;
      }

      try {
        setUserId(analytics, userId);
      } catch (e) {
        console.log("Unable to set user id", e);
      }
    },
    [features?.analyticsAuthEnabled],
  );

  const setUserDetails = useCallback(
    (properties: UserProperties) => {
      if (!features.analyticsAuthEnabled) {
        return;
      }

      try {
        setUserProperties(analytics, {
          department_id: properties.departmentId,
          organisation_id: properties.organisationId,
        });
      } catch (e) {
        console.log("Unable to set user properties id", e);
      }
    },
    [features?.analyticsAuthEnabled],
  );

  return (
    <AnalyticsContext.Provider value={{ log, setUser, setUserDetails }}>
      {children}
    </AnalyticsContext.Provider>
  );
}
