import {
  Auth,
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";
import { app } from "~/integrations/firebase/app";

export const auth: Auth = getAuth(app);

if (import.meta.env.VITE_FIREBASE_EMULATORS_ENABLED) {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
} else {
  // tenant support in emulator is limited so we only use the tenant for actual deployments
  auth.tenantId = import.meta.env.VITE_FIREBASE_AUTH_TENANT_ID;
}

export const googleAuthProvider = new GoogleAuthProvider();
