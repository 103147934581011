import { styled } from "styled-components";

const Container = styled.div`
  background: var(--noa-black);
  width: 100%;
  height: 0.5rem;
  border-radius: 1000px;
`;

const Progress = styled.div<{
  $progress: number;
}>`
  width: ${({ $progress }) => $progress}%;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: var(--noa-gradient);
  height: 0.5rem;
  border-radius: 1000px;
`;

interface Props {
  progress: number;
}

export function ProgressBar({ progress }: Props) {
  return (
    <Container>
      <Progress $progress={progress} />
    </Container>
  );
}
