import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Content,
  Details,
  FailedIcon,
  Message,
  ModalContainer,
  Title,
} from "./styled";
import { Text } from "~/components/Text";

import { UploadScreen } from "./UploadScreen";
import { UploadingScreen } from "./UploadingScreen";

interface Props {
  onClose(): void;
}

export function FileUploadModal({ onClose }: Props) {
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string>();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => setFiles(acceptedFiles),
    [],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFileDialogActive,
    fileRejections,
  } = useDropzone({
    multiple: false,
    maxFiles: 1,
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "text/csv": [".csv"],
      "application/epub+zip": [".epub"],
      "application/x-iwork-pages-sffpages": [".pages"],
      "text/plain": [".txt"],
      "text/html": [".html"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  function onClear() {
    setFiles([]);
    fileRejections.splice(0, fileRejections.length);
    setError(undefined);
  }

  const isActive = isDragActive || isFileDialogActive;

  return (
    <ModalContainer show={true} onClose={onClose} $isActive={isActive}>
      {error ? (
        <Content>
          <Details>
            <FailedIcon />

            <Title>
              <Text id="files.upload.failed.title" />
            </Title>

            <Message>
              <Text id="files.upload.failed.message" multiline />
            </Message>
          </Details>

          <Button onClick={onClear}>
            <Text id="files.upload.invalid-files.try-again-button" />
          </Button>
        </Content>
      ) : (
        <>
          {files.length && !fileRejections.length ? (
            <UploadingScreen
              files={files}
              setError={setError}
              onClose={onClose}
            />
          ) : (
            <UploadScreen
              invalidFiles={fileRejections}
              isActive={isActive}
              rootProps={getRootProps()}
              inputProps={getInputProps()}
              onClear={onClear}
            />
          )}
        </>
      )}
    </ModalContainer>
  );
}
