import React, { useEffect } from "react";
import { FormattedMessage } from "~/components/Message";
import { useTypingAnimation } from "~/hooks/useTypingAnimation";
import { styled } from "styled-components";

interface BotResponseProps {
  response: string;
  isLoading?: boolean;

  onMessageContentChange(): void;
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Dot = styled.div<{ $index: number }>`
  width: 0.7rem;
  height: 0.7rem;
  margin-top: 0.25rem;
  border-radius: 1000px;
  background: #98F4CC;
  margin-right: 0.35rem;
  animation: pulse 1s infinite ease-in-out;
  animation-delay: ${({ $index }) => $index * 0.2}s;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.7);
  transform: scale(0.9) translateY(0);

  @keyframes pulse {
    0% {
      transform: scale(0.9) translateY(0);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.7);
    }
    50% {
      transform: scale(1) translateY(-0.5rem);
      box-shadow: 0 0.5rem 5px 1px rgba(0, 0, 0, 0.4);
    }
    100% {
      transform: scale(0.9) translateY(0);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.7);
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

function Loader({ dots = 3, className = "" }) {
  return (
    <Container className={className}>
      {Array.from(Array(dots)).map((_, index) => (
        <Dot key={index} $index={index} />
      ))}
    </Container>
  );
}

const BotResponse: React.FC<BotResponseProps> = ({
  response,
  isLoading,
  onMessageContentChange,
}) => {
  const displayedText = useTypingAnimation(response, isLoading, 1, 300);

  useEffect(() => {
    onMessageContentChange();
  }, [displayedText, response, isLoading]);

  if (isLoading && !displayedText) {
    return (
      <>
        &nbsp;
        <Loader dots={3} />
        &nbsp;
      </>
    );
  }

  return (
    <FormattedMessage
      content={isLoading ? displayedText : response}
      withCursor={isLoading}
    />
  );
};

export default BotResponse;
