import React from "react";

import { styled } from "styled-components";
import { FadeIn } from "~/components/FadeIn";
import { Text } from "~/components/Text";
import { LabsContainer } from "~/components/LabsAvatar";

const Container = styled(FadeIn)`
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  padding: 15rem 2rem;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled(FadeIn)`
  font-size: 1.625rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;

const Description = styled(FadeIn)`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.68rem;
  margin-bottom: 2rem;
`;

const LabsAvatar = styled(LabsContainer)`
  position: absolute;
  bottom: -250px;
  width: 100%;
  height: 500px;
`;

const MigrationPage: React.FC = () => {
  return (
    <>
      <Container>
        <Title>
          <Text id="maintenance.title" />
        </Title>

        <Description delay={100}>
          <Text id="maintenance.subtitle" multiline />
        </Description>
      </Container>
      <LabsAvatar />
    </>
  );
};

export default MigrationPage;
