import React from "react";

import { useApplication } from "~/context/ApplicationContext";
import Header from "./components/Header";
import Routes from "~/routes";

import "./styles/App.css";
import MigrationPage from "~/routes/Maintenance";

function App() {
  const { maintenanceEnabled } = useApplication();

  if (maintenanceEnabled) {
    return <MigrationPage />;
  }

  return (
    <>
      <Header />
      <Routes />
    </>
  );
}

export default App;
