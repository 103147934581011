import React, { useState } from "react";
import { useAuth } from "~/context/AuthContext";
import BotResponse from "./BotResponse";
import { styled } from "styled-components";
import { ChatMessage, ChatStatus, Role, RatingType } from "~/types/Chat";
import { useChat } from "~/context/ChatContext";
import { FeedbackPopup } from "./FeedbackPopup";
import { FormattedMessage } from "~/components/Message";
import { ChatItemActions } from "~/components/ChatItemActions";
import { LabsContainer } from "~/components/LabsAvatar";
import { useApplication } from "~/context/ApplicationContext";
import { useAnalytics } from "~/context/AnalyticsProvider";
import ChatItemInProgress from "./ChatItemInProgress";

const LabsChatAvatar = styled.div<{ size: number }>`
  border-radius: 1000px;
  background: linear-gradient(180deg, #98f4cc 0%, #120a27 100%);
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  box-shadow: 0 0 ${({ size }) => size / 2}px #98f4cc70;
  transition: all 0.5s ease-in-out;
  margin-top: 0.3rem;
`;

const Avatar = styled.img`
  border-radius: 999px;
  margin-top: 0.3rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  margin: 0 1rem;
  grid-gap: 1.5rem;
  @media (max-width: 600px) {
    display: flex;
    flex-flow: column wrap;
    margin: 2px;
  }
`;

const Message = styled.div<{ $received?: boolean }>`
  background: #212121;
  border: 2px solid #98969e;
  padding: 1rem 1.25rem;
  border-radius: 18px;
  transition: border 0.2s linear;
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const GutterLeft = styled.div`
  width: 90px;
  display: flex;
  justify-content: end;
  @media (max-width: 600px) {
    width: 75px;
  }
`;

const GutterRight = styled.div`
  width: 82px;
  @media (max-width: 600px) {
    width: 0px !important;
  }
`;

const UserItem: React.FC<{ message: ChatMessage }> = ({ message }) => {
  const { authUser } = useAuth();

  return (
    <Container>
      <GutterLeft>
        <Avatar
          referrerPolicy="no-referrer"
          width={50}
          height={50}
          src={authUser!.photoURL!}
          alt="Labs"
        />
      </GutterLeft>

      <MessageContent className="reset">
        <Message>
          <FormattedMessage content={message.content ?? ""} />
        </Message>
      </MessageContent>

      <GutterRight />
    </Container>
  );
};

const LabsAvatar = styled(LabsContainer)`
  width: 50px;
  height: 50px;
  position: absolute;
`;

interface AssistantItemProps {
  message: ChatMessage;
  isLoading: boolean;
  isLatestMessage: boolean;

  onMessageContentChange(): void;
}

function AssistantItem({
  message,
  isLoading = false,
  onMessageContentChange,
  isLatestMessage,
}: AssistantItemProps) {
  const { rateMessage, chat, regenerateMessage } = useChat();
  const { features } = useApplication();
  const { log } = useAnalytics();
  const [showPopup, setShowPopup] = useState(false);
  const lastNotCompletedAction = message.actions
    ?.slice()
    .reverse()
    .find((action) => !action.completed);

  async function onLike() {
    if (message.rating?.value) {
      return;
    }

    await onSubmitFeedback(RatingType.LIKE, null);
    log({
      type: "chat_message_liked",
      payload: {
        chatId: chat.id,
        departmentId: chat.departmentId,
        messageId: message.id,
      },
    });
  }

  async function onDislike() {
    if (message.rating?.value) {
      return;
    }

    await onSubmitFeedback(RatingType.DISLIKE, null);

    setShowPopup(true);

    log({
      type: "chat_message_disliked",
      payload: {
        chatId: chat.id,
        departmentId: chat.departmentId,
        messageId: message.id,
      },
    });
  }

  async function onSubmitFeedback(
    rating: RatingType,
    feedbackText: string | null
  ) {
    try {
      await rateMessage(message.id, rating, feedbackText);
      setShowPopup(false);
    } catch (e) {
      console.error("Error persisting feedbackText:", e);
    }
  }

  async function onFeedbackSubmit(rating: RatingType, feedbackText: string) {
    await onSubmitFeedback(rating, feedbackText);

    log({
      type: "chat_message_feedback_submitted",
      payload: {
        chatId: chat.id,
        departmentId: chat.departmentId,
        messageId: message.id,
        feedbackLength: feedbackText?.length ?? 0,
      },
    });
  }

  const isReady = chat.status === ChatStatus.READY;
  const isDisplayActions = isReady || !isLatestMessage;

  async function onCopy() {
    const element = document.querySelector(
      `#botMessage[data-id="${message.id}"] div`
    );

    if (!element) {
      console.error("Could not find element to copy");
      return false;
    }

    await navigator.clipboard.write([
      new ClipboardItem({
        // Copy as HTML to retain formatting
        "text/html": new Blob([element.innerHTML!], {
          type: "text/html",
        }),

        // Copy as plain text for when it's pasted into a plain text editor
        "text/plain": new Blob([message.content!], {
          type: "text/plain",
        }),
      }),
    ]);

    log({
      type: "chat_message_copied",
      payload: {
        chatId: chat.id,
        departmentId: chat.departmentId,
        messageId: message.id,
      },
    });

    return true;
  }

  return (
    <Container>
      <GutterLeft>
        {isLatestMessage && <LabsAvatar opacity={0} />}
        <LabsChatAvatar size={50} />
      </GutterLeft>
      <MessageContent>
        {message.actions?.map((action) => (
            <ChatItemInProgress
              key={action.id}
              action={
                isLatestMessage && isLoading
                  ? `Using tool ${action.name} with input`
                  : `Used ${action.name} with input`
              }
              completed={action.completed && (!isLatestMessage || !isLoading)}
              actionInput={action.input}
            />
          ))}

        <Message
          style={{
            borderColor: "#98F4CC",
          }}
        >
          <div id="botMessage" data-id={message.id} className="reset">
            <BotResponse
              key={message.id}
              response={message.content || ""}
              isLoading={isLoading && isLatestMessage}
              onMessageContentChange={onMessageContentChange}
            />
          </div>
        </Message>

        {isDisplayActions && (
          <ChatItemActions
            key={chat.id}
            onLike={onLike}
            onDislike={onDislike}
            onRegenerate={regenerateMessage}
            onCopy={onCopy}
            rating={message.rating}
            showRegenerate={
              features.messageRegenerationEnabled && isLatestMessage
            }
          />
        )}
      </MessageContent>
      <GutterRight />

      {showPopup && (
        <FeedbackPopup
          onClose={() => setShowPopup(false)}
          onSubmit={(feedbackText) =>
            onFeedbackSubmit(RatingType.DISLIKE, feedbackText)
          }
        />
      )}
    </Container>
  );
}

interface Props {
  message: ChatMessage;
  isLoading: boolean;
  isLatestMessage: boolean;

  onMessageContentChange(): void;
}

export function ChatItem({
  message,
  isLoading,
  onMessageContentChange,
  isLatestMessage,
}: Props) {
  return (
    <div
      data-testid={
        isLoading ? "chat-message-loading result-streaming" : "chat-message"
      }
      id={`navPrompt-${message.content?.replace(/[^a-zA-Z0-9]/g, "-")}`}
    >
      {message.role === Role.USER ? (
        <UserItem message={message} />
      ) : (
        <AssistantItem
          message={message}
          isLoading={isLoading}
          onMessageContentChange={onMessageContentChange}
          isLatestMessage={isLatestMessage}
        />
      )}
    </div>
  );
}
