import { Locale, locales, LocaleStrings } from "~/locales";
import { memo, useMemo } from "react";
import ReactMarkdown from "react-markdown";

interface Props {
  id: LocaleStrings;
  locale?: Locale;
  multiline?: boolean;
}

export function text(id: LocaleStrings, locale?: Locale) {
  const strings = locales[locale || "en"];

  if (!strings) {
    return id;
  }

  const value = strings[id];

  return value || id;
}

export const Text = memo(function ({ id, locale = "en", multiline }: Props) {
  const strings = locales[locale];

  const elements = useMemo(() => {
    const elements = ["strong", "i", "a"];

    return multiline ? elements.concat(["p"]) : elements;
  }, [multiline]);

  if (!strings) {
    return <div>{id}</div>;
  }

  const value = text(id, locale);

  return (
    <ReactMarkdown allowedElements={elements} unwrapDisallowed={true}>
      {value}
    </ReactMarkdown>
  );
});
